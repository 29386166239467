import React, { useState } from 'react'
import gql from 'graphql-tag'
import { get } from 'ts-object-path'
import { useQuery } from '@apollo/react-hooks'
import {
  PartnerByIdTimeline,
  PartnerByIdTimelineVariables
} from '../../_generated/PartnerByIdTimeline'

export const QUERY = gql`
query PartnerByIdTimeline($id: ID!, $offset: Int!){
  timeline {
    byPartnerId(id: $id, days: { limit: 10, offset: $offset }) {
      date
      newClientsInStructure {
        id
        name
        email
      }
      newPartnersInStructure {
        id
        name
        email
        position
      }
      strategyConnectionHistory {
        connectionDate
        type
        client {
          id
          email
          name
        }
        strategy {
          id
          name
        }
      }
      transactions {
        type
        value
        client {
          id
          email
          name
        }
        account {
          id
          number
          currency
        }
      }
    }
  }
}`

export type Q = PartnerByIdTimeline
export type V = PartnerByIdTimelineVariables
export type R = Q['timeline']['byPartnerId']

export const parseData = (data: Q): R => {
  return get(data, p => p.timeline.byPartnerId)
}

export const parseMoreData = (prevResult: Q, fetchMoreResult: Q): Q => {
  if (!fetchMoreResult) return prevResult
  const timeline = [...parseData(prevResult), ...parseData(fetchMoreResult)]
  const r: Q = JSON.parse(JSON.stringify(prevResult))
  r.timeline.byPartnerId = timeline
  return r
}

type T = {
  partnerId: string
  offset?: number
}

export function Use<T>({ partnerId: id, offset = 0 }) {
  const [loadingMore, setLoadingMore] = useState(false)
  const query = useQuery<Q, V>(
    QUERY,
    {
      variables: {
        id,
        offset,
      }
    }
  )

  const { loading, error, data, fetchMore } = query
  const parsecData = parseData(data)

  type TM = {
    limit: number
  }

  const more = () => {
    setLoadingMore(true)
    
    fetchMore({
      variables: {
        offset: parsecData.length
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        setLoadingMore(false)
        return parseMoreData(prev, fetchMoreResult)
      }
    })

    return () =>{}
}

  return { loading, error, data: parsecData, fetchMore, parseMoreData, more, loadingMore }
}
