import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { BigChartFragment } from '../../../fragments/BigChart'

import { Use as ProfitChartData } from '../../../data/partners/byId/charts/profit'

const PartnerProfitChart: React.FunctionComponent<{ partnerId: string, tkey: string }> = ({ partnerId, tkey }) => {
    const { t } = useTranslation(localStorage.getItem('localeBranch'))

    return <>
        <BigChartFragment
            header={t(`${tkey}.data.charts.profit.header`)}
            desc={t(`${tkey}.data.charts.profit.desc`)}
            type="bar"
            cols={[{ key: 'value', color: '#07ada3', label: t('structure.data.charts.profit.header'), data: ProfitChartData(partnerId) }]}
        />
    </>
}

export { PartnerProfitChart }
