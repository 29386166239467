import gql from 'graphql-tag'
import { get } from 'ts-object-path'
import { useQuery} from '@apollo/react-hooks'
import {
  PartnerByIdCountsDeep,
  PartnerByIdCountsDeepVariables
} from '../../_generated/PartnerByIdCountsDeep'

export const QUERY = gql`
query PartnerByIdCountsDeep($id: ID!){
  partners {
    byId(id: $id) {
      id
      counts {
        sbibs
        bibs
        ibs
        clients
        
        inStructure {
          sbibs
          bibs
          ibs
          clients
        }
      }
    }
  }
}`

export type Q = PartnerByIdCountsDeep
export type V = PartnerByIdCountsDeepVariables
export type R = Q['partners']['byId']['counts']

export const parseData = (data: Q): R => {
  return get(data, p => p.partners.byId.counts)
}

export function Use(partnerId: string) {
  const query = useQuery<Q, V>(
    QUERY,
    {
      variables: {
        id: partnerId,
      }
    }
  )

  const { loading, error, data } = query

  return { loading, error, data: parseData(data) }
}
