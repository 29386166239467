import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

i18n
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en',
    load: 'languageOnly', // we only provide en, cs -> no region specific locals like en-US
    
    defaultNS: 'original',
    debug: true,
    //saveMissing: true,
    appendNamespaceToCIMode: true,
    keySeparator: '.',

    backend: {
      loadPath: (process.env.NODE_ENV === 'production')
        ? '/locales/{{lng}}/{{ns}}/translations.json'
        : 'http://localhost:8080/locales/{{lng}}/{{ns}}/translations.json',
    },

    interpolation: {
        escapeValue: false, // not needed for react!!
        formatSeparator: ',',
        format: (value, format, lng) => {
            if (format === 'uppercase') return value.toUpperCase()
            return value
        }
    },
    react: {
      bindI18n: 'languageChanged editorSaved',
      //useSuspense: false,
    },
  });

export default i18n;
