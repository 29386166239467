import * as React from 'react'
import { useParams, Link } from 'react-router-dom'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

import { simpleId } from '../../../utils'
import { Money } from '../../../components/Money'
import { Table, NoItemsMessage } from '../../../components/TradesTable'
import { LoadingArea } from '../../../components/LoadingArea'
import { LoadMoreBtn } from '../../../components/LoadMoreBtn'

import { Use as FeesData } from '../../../data/accounts/byId/table/history/fees'

export const FeesTable = styled(Table)`
    @media only screen and (max-width: 800px) {
        .type {
            display: none;
        }
    }

    .amount {
        text-align: right;
    }
`

export const AccountDetailsFeesPage: React.FunctionComponent<{}> = () => {
    const { t } = useTranslation(localStorage.getItem('localeBranch'))
    const { accountId, type } = useParams()

    const limit = 20
    const nextLimit = 40

    const { loading, error, data: items, more, loadingMore } = FeesData({
        accountId,
        limit
    })

    if (loading) return <LoadingArea size={400} />

    if (items.length < 1) return <NoItemsMessage>No fees.</NoItemsMessage>

    return <>
        <FeesTable>
            <thead>
                <tr>
                    <td className="date">{t('account.table.date')}</td>
                    <td className="type">{t('account.table.type')}</td>
                    <td className="amount">{t('account.table.amount')}</td>
                </tr>
            </thead>
            <tbody>
                {items.map(item => <tr key={simpleId()}>
                    <td className="date">{item.date}</td>
                    <td className="type">{item.type}</td>
                    <td className="amount"><Money value={item.amount} currency={item['currency']} />  </td>
                </tr>)}
            </tbody>
        </FeesTable>

        <br />

        {items.length % limit === 0  // Are available more items?
            ? loadingMore
                ? <LoadingArea />
                : <LoadMoreBtn action={() => more({ limit: nextLimit })}>{t('fetchMore')}</LoadMoreBtn>
            : <></>
        }
    </>
}

