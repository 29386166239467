import * as React from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { simpleId } from '../utils'

const BRAND = 'pt'

export const FooterFragment: React.FunctionComponent<{}> = () => {
    const { t, i18n } = useTranslation()

    const foot = {
        pt: 'terms pamm complaints conflicts classification compensation facta orderExec privacy risk ibBudgetAppendix etfAddendum'.split(' '),
        axiory: 'terms conflicts facta orderExec privacy risk'.split(' ')
    }

    return <Footer>
        <p>{t(`app.footer.${BRAND}.disclaimer`)}</p>
        <ul>
            {foot[BRAND].map(o => <li key={simpleId()}>
                <a target="_blank" href={t(`app.footer.${BRAND}.links.${o}.link`)}>
                    {t(`app.footer.${BRAND}.links.${o}.title`)}
                </a>
            </li>)}
        </ul>
    </Footer>
}

const Footer = styled.footer`
    max-width: 1150px;
    margin: auto;
    margin-top: 60px;
    margin-bottom: 50px;
    font-size: 0.9rem;
    line-height: 1rem;

    @media (max-width: 1200px) {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    p {
        opacity: 0.7;
    }

    ul {
        padding-top: 10px;
        list-style: none;

        li {
            
            padding-right: 5px;
            margin-right: 5px;
            display: inline-block;

            &:not(:last-child) {
                border-right: 1px solid #B7B6B6;
            }

            a {
                color: #9d38da;
                text-decoration: none;
            }
        }
    }
`
