import React, { useState } from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

import { Use as clientData } from '../data/clients/byId/details'
import { Use as accountData } from '../data/accounts/byId/details'
import { BreadCrumbsFragment } from '../components/BreadCrumbs'
import { BackButton } from '../components/BackButton'
import { TopContainer } from './PartnerHeader'

export const AccountHeaderFragment: React.FunctionComponent<{ clientId: string, accountId: string, accountNumber: number }> = ({ clientId, accountId, accountNumber }) => {
    const { t } = useTranslation(localStorage.getItem('localeBranch'))
    const { loading, error, data } = clientData(clientId)
    const { loading: loadingAccount, error: errorAccount, data: dataAccount } = accountData(accountId)

    if (loading) return <div>
        <BackButton>{t('backToYou')}</BackButton>
        <TopContainer>
            <BreadCrumbsFragment items={[]} />
            <h1><span></span></h1>
        </TopContainer>
    </div>

    const { name, ancestors, id } = data

    const breadCrumbsItems = ancestors[0].map(ancestor => {
        return {
            name: ancestor.name,
            link: `/partner/${ancestor.id}`
        }
    })

    breadCrumbsItems.push({
        name,
        link: `/client/${id}`
    })

    breadCrumbsItems.push({
        name: '#' + accountNumber,
        link: `/account/${accountId}`
    })

    return <>
        <BackButton>{t('backToYou')}</BackButton>
        <AccountTopContainer>
            <BreadCrumbsFragment items={breadCrumbsItems} />
            <h1>{t('account.title')}<em>{accountNumber}</em> </h1>
            <div>
                {!loadingAccount
                    ? (dataAccount.strategyConnections.status
                        ? <StrategyOrType>
                            <span className="platform">{dataAccount.platform}</span>
                            <span className={dataAccount.strategyConnections.status.toLowerCase().split('_')[0]}>
                                {t('status.' + dataAccount.strategyConnections.status)}
                            </span>
                            {dataAccount.strategyConnections.strategy && dataAccount.strategyConnections.strategy.name}
                        </StrategyOrType>
                        : <StrategyOrType><span className="platform">{dataAccount.platform}</span> <em>{dataAccount.type}</em></StrategyOrType>
                    )
                    : <></>
                }
            </div>
        </AccountTopContainer>
    </>
}

const StrategyOrType = styled.div`
padding: 0 25px 0 35px;
position: relative;
top: -1.5rem;

em {
    background: black;
    font-size: 12px;
    position: relative;
    padding: 2px 5px;
    margin: 0 10px 0 0;
    color: white;
    text-transform: uppercase;
    display: inline-block;
}
.platform {
    background: #8746bd;
    font-size: 12px;
    position: relative;
    padding: 2px 5px;
    margin: 0 10px 0 0;
    color: white;
    text-transform: uppercase;
    display: inline-block;
}
.connected {
    background: #07ada3;
    font-size: 12px;
    position: relative;
    padding: 2px 5px;
    margin: 0 10px 0 0;
    color: white;
    text-transform: uppercase;
    display: inline-block;
}
.disconnected {
    background: gray;
    font-size: 12px;
    position: relative;
    padding: 2px 5px;
    margin: 0 10px 0 0;
    color: white;
    text-transform: uppercase;
    display: inline-block;
}
.pending {
    background: #ff654d;
    font-size: 12px;
    position: relative;
    padding: 2px 5px;
    margin: 0 10px 0 0;
    color: white;
    text-transform: uppercase;
    display: inline-block;
}
`

const AccountTopContainer = styled(TopContainer)`
h1 {
    padding-left: 35px;
}
`
