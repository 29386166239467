import gql from 'graphql-tag'
import { get } from 'ts-object-path'
import { useQuery } from '@apollo/react-hooks'
import {
  DepositsChartByClientId_clientCharts,
  DepositsChartByClientId_clientChartsVariables
} from '../../../_generated/DepositsChartByClientId_clientCharts'
import { Precision } from '../../../_generated/globalTypes'

export const QUERY = gql`
query DepositsChartByClientId_clientCharts($id: ID!, $precision: Precision!) {
  charts {
    byClientId(id: $id) {
      id: clientId
      deposits(precision: $precision) {
        begin
        valuesCount
        values
        labels
        currency
      }
    }
  }
}`

export type Q = DepositsChartByClientId_clientCharts
export type V = DepositsChartByClientId_clientChartsVariables
export type R = Q['charts']['byClientId']['deposits']

export const parseData = (data: Q): R => {
  return get(data, p=> p.charts.byClientId.deposits)
}

export function Use(clientId: string) {
  const query = useQuery<Q, V>(
      QUERY,
      {
          variables: {
              id: clientId,
              precision: Precision.MONTH
          }
      }
  )

  const { loading, error, data } = query

  return { loading, error, data: parseData(data) }
}
