export const isValid = (input) => {
    return (input.validate && input.validate()) || (input.checkValidity && input.checkValidity() || (input.checked))
}

export const isEnterKey = (e) => {
    return e.key === 'Enter' || e.keyCode === 13
}

export const nextOrSubmit = (e, inputs, submit) => {
        const { currentTarget: inputActive } = e

        const invalidInputs = inputs
            .map(i => i.current && !isValid(i.current) && i.current)
            .filter(i => !!i)

        console.log(invalidInputs)

        if(invalidInputs.length) {
            invalidInputs[0].focus()
        }
        else {
            submit(e)
        }
}

export const nextOrSubmitOnEnter = (e, inputs, submit) => {
    if (isEnterKey(e)) {
        nextOrSubmit(e, inputs, submit)
    }
}
