import gql from 'graphql-tag'
import { get } from 'ts-object-path'
import { useQuery} from '@apollo/react-hooks'
import {
  ClientByIdAccounts,
  ClientByIdAccountsVariables
} from '../../_generated/ClientByIdAccounts'

export const QUERY = gql`
query ClientByIdAccounts($id: ID!){
  clients {
    byId(id: $id) {
      id
      accounts {
        id
        number
        currency
        status
        type
        balance
        equity
        profit
        history {
          sums {
            fees {
              amount
            }
            transactions {
              deposits {
                amount
              }
              withdrawals {
                amount
              }
            }
          }
        }
        strategyConnections {
          status
          strategy {
            name
          }
        }
      }
    }
  }
}`

export type Q = ClientByIdAccounts
export type V = ClientByIdAccountsVariables
export type R = Q['clients']['byId']['accounts']

export const parseData = (data: Q): R => {
  return get(data, p => p.clients.byId.accounts)
}

export const fetchMoreParse = (prevResult: Q, fetchMoreResult: Q): Q => {
  if (!fetchMoreResult) return prevResult
  const accounts = [...parseData(prevResult), ...parseData(fetchMoreResult)]
  const r: Q = JSON.parse(JSON.stringify(prevResult))
  r.clients.byId.accounts = accounts
  return r
}

export function Use(clientId: string) {
  const query = useQuery<Q, V>(
      QUERY,
      {
          variables: {
              id: clientId,
          }
      }
  )

  const { loading, error, data } = query

  return { loading, error, data: parseData(data) }
}
