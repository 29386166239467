import * as React from 'react'
import styled from '@emotion/styled'

const P1 = styled.div` animation-delay: -0.45s !important; `
const P2 = styled.div` animation-delay: -0.3s !important; `
const P3 = styled.div` animation-delay: -0.15s !important; `

const SpinnerDiv = styled.div`
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;

    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -32px;
    margin-left: -32px;

    div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 51px;
        height: 51px;
        margin: 6px;
        border: 6px solid #522974;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #522974 transparent transparent transparent;
    }

    @keyframes lds-ring {
        0% {
            transform: rotate(0deg);
            border-color: #522974 transparent transparent transparent;
            
        }
        50% {
            transform: rotate(180deg);
            border-color: #07ada3 transparent transparent transparent;
            
        }
        100% {
            transform: rotate(360deg);
            border-color: #522974 transparent transparent transparent;
        }
    }
    
`



export const Spinner: React.FunctionComponent = () => {
    return <SpinnerDiv><div></div><P1></P1><P2></P2><P3></P3></SpinnerDiv>
}
