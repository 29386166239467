import * as React from 'react'
import { ReferenceLine, BarChart, Area, AreaChart, Cell, Bar, CartesianGrid, ResponsiveContainer, XAxis, YAxis, Tooltip } from 'recharts'
import moment from 'moment'
import { positiveColor, negativeColor, abbreviateNumber, gradientOffset/*, gradientHtml */ } from './utils'
import { CustomTooltip } from './Tooltip'
import { simpleId } from '../../utils'

export type ChartType = 'bar' | 'area'

type T = {
    id: any
    type: ChartType
    height?: number | string
    cols: any
    data: any
    currency?: string
    labels: any
}

export const ChartBig: React.SFC<T> = ({ id, type, cols, data, currency, labels, height = 250 }) => {

    const { off, max, min } = gradientOffset(data)
    const ChartElem = type == 'bar' ? BarChart : AreaChart

    return <>
        <div className="">
            <ResponsiveContainer width="100%" height={height}>
                <ChartElem data={data} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                    <CartesianGrid vertical={false} strokeDasharray="1 5" stroke="#b8b8b8" fill="#f9f9f9" />
                    <XAxis
                        dataKey="label"
                        stroke="#b8b8b8"

                        tickFormatter={(v) => {
                            if (moment(v).format('M') == '1') {
                                return moment(v).format('Y')
                            }
                            else {
                                return moment(v).format('MMM')
                            }

                        }}

                    />
                    <YAxis
                        orientation="right"
                        tick={props => <text {...props}>{`${abbreviateNumber(props.payload.value)} ${currency}`}</text>}
                        stroke="#b8b8b8"
                    ></YAxis>

                    {
                        min >= 0 &&  // Only green (+)
                        <defs>
                            <linearGradient id={`splitColor${id}`} x1="0" y1="0" x2="0" y2="1">
                                <stop offset={0} stopColor={positiveColor} stopOpacity={1} />
                            </linearGradient>
                        </defs>
                    }
                    {
                        max <= 0 &&  // Only red (-)
                        <defs>
                            <linearGradient id={`splitColor${id}`} x1="0" y1="0" x2="0" y2="1">
                                <stop offset={0} stopColor={negativeColor} stopOpacity={1} />
                            </linearGradient>
                        </defs>
                    }
                    {
                        min < 0 &&  // Green and Red (+-)
                        <defs>
                            <linearGradient id={`splitColor${id}`} x1="0" y1="0" x2="0" y2="1">
                                <stop offset={off} stopColor={positiveColor} stopOpacity={1} />
                                <stop offset={off} stopColor={negativeColor} stopOpacity={1} />
                            </linearGradient>
                        </defs>
                    }

                    <Tooltip content={<CustomTooltip currency={currency} labels={labels} />} />

                    <ReferenceLine y={0} stroke="gray" strokeDasharray="1 1" />
                    {type == 'bar'
                        ? cols.map(col => <Bar type="monotone" dataKey={col.key} fill={`url(#splitColor${id})`} key={simpleId()}>
                            {data.map((entry, index/*, x, y*/) => {
                                if (col.key === 'value') { // TODO! je to tu provizorne (mozna ne)
                                    return <Cell key={`cell-${index}`} fill={entry[col.key] > 0 ? positiveColor : negativeColor} />
                                }
                                else {
                                    return <Cell key={`cell-${index}`} fill={col.color} />
                                }
                            })
                            }
                        </Bar>
                        )
                        : cols.map(col => <Area type="monotone" dataKey={col.key} fill={`url(#splitColor${id})`} stroke="none" key={simpleId} />)
                    }
                </ChartElem>
            </ResponsiveContainer>
        </div>
    </>
}

