import * as React from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'

import { Money } from '../components/Money'
import { ChartSmall } from '../components/charts/ChartSmall'
import { Spinner } from '../components/Spinner'

import { Use as PartnersVolumeData } from '../data/partners/byId/charts/volume'
import { Use as ClientsBalanceData } from '../data/clients/byId/charts/balance'
import { Use as AccountsBalanceData } from '../data/accounts/byId/charts/balance'

const fillChartItems = (itemsCount: number, vals?: number[]) => {
    const year = new Array(itemsCount).fill(0)
    if (vals) {
        const valsCopy = JSON.parse(JSON.stringify(vals))
        return valsCopy
            ? year.map(v => {
                let val = valsCopy.pop()
                return val ? val : v
            }).reverse()
            : year
    }
    else {
        return year
    }
}

const CardChart: React.SFC<{ id: any, type: string }> = ({ id, type }) => {
    const { loading, error, data } = (() => {
        switch (type) {
            case 'partners':
                return PartnersVolumeData(id)
            case 'clients':
                return ClientsBalanceData(id)
            case 'accounts':
                return AccountsBalanceData(id)
        }
    })()

    if (loading) return <Loader>
        <Spinner />
    </Loader>
    if (error) return <div>Error!</div>

    const items = fillChartItems(12, data ? data.values : [])
        .map(i => { return { value: i } })  // [1, 2] => [{value: 1}, {value: 2}]

    const chartTypes = {
        partners: 'bar',
        clients: 'line',
    }

    return <ChartSmall
        id={id}
        type={chartTypes[type]}
        cols={[{ key: 'value', color: '#07ada3' }]}
        data={items}
    />
}

type dataItemType = {
    label: string
    value: number
    type?: string
}

type CardType = {
    title: string
    currency: string
    type: string
    data: dataItemType[]
    id: any
    strategyConnections?: any
}

const Card: React.FunctionComponent<CardType> = ({ title, currency, data, id, type, strategyConnections }) => {
    const { t } = useTranslation(localStorage.getItem('localeBranch'))

    return <>
        <Container>
            <Link to={`/${type.slice(0, -1)}/${id}`}>
                {type === 'accounts'
                    ? <Title>
                        #{title}
                        {strategyConnections && strategyConnections.status &&
                            <Status className={strategyConnections.status.toLowerCase().split('_')[0]}>
                                <strong>•</strong> {strategyConnections.strategy
                                    ? strategyConnections.strategy.name
                                    : t('accountCard.disconnected')
                                }
                            </Status>
                        }
                    </Title>
                    : <Title>{title}</Title>
                }
                <ChartConteiner>
                    {type === 'partners' && <CardChart id={id} type={type} />}
                    {type === 'clients' && <CardChart id={id} type={type} />}
                    {type === 'accounts' && <CardChart id={id} type={type} />}
                </ChartConteiner>
                <ValuesTable>{data.map(item =>
                    <div key={item.value + '' + item.label}>
                        <span className="_label">{t('accountCard.' + item.label)}</span>
                        <span className="_value">{item.type === 'currency' ? <Money value={item.value} currency={currency} /> : item.value}</span>
                    </div>
                )}</ValuesTable>
            </Link>
        </Container>
    </>
}

export { Card }

const Container = styled.div`
    display: flex;
    flex-direction: column;
    
    padding: 20px 0;
    border: 1px solid hsla(240,4%,64%,.4);
    //display: inline-block;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    width: 100%;
    border-radius: 6px;
    border: 1px solid rgba(0,0,0,.1);
    border-bottom: 1px solid rgba(0,0,0,.2);
    border-top: 1px solid rgba(0,0,0,.05);
    box-shadow: 0 1px 4px 0 #e9e9e9;
    transition: box-shadow .15s linear;
    cursor: pointer;
    
    &:hover {
        box-shadow: 0 4px 15px #dfdfdf;
        border-color: rgba(157,56,218,.5);
        border-bottom-color: #9d38da;
    }

    a {
        text-decoration: none;
        color: black;
        position: relative;
        display: block;

        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: transparent;
        }
    }
    
`
const Title = styled.div`
    text-align: center;
    font-family: Aventir medium,Arial,sans-serif;
    font-size: 15px;
    color: #000;
    line-height: 14px;
    height: 60px;
`

const Status = styled.div`
    &.connected {
        strong {
            color: #07ada3;
            font-size: 30px;
            position: relative;
            top: 5px;
        }
    }
    &.disconnected {
        color: gray;
        strong {
        font-size: 30px;
            position: relative;
            top: 5px;
        }
    }
    &.pending {
        strong {
            color: #ff654d;
            font-size: 30px;
            position: relative;
            top: 5px;
        }
    }
`

const ChartConteiner = styled.div`
    height: 100px;
    padding: 0 25px;

    /*
    @media (max-width: 600px) {
        height: 60px;
    }
    */
`

const ValuesTable = styled.div`
    padding: 10px 30px;
    font-family: Aventir heavy,Arial,sans-serif;
    font-size: 13px;
    line-height: 15px;
    color: #4a4a4a;

    & > div {
        display: flex;

        ._label {
            flex: 1;
            font-size: .8rem;
            line-height: 1.1rem;
            color: rgba(0,0,0,.6);
        }

        ._value {
            font-size: 14px;
            color: #4a4a4a;
        }
    }
`

const Loader = styled.div`
    position: relative;

    > div {
        transform: scale(.6);
        filter: grayscale(1) brightness(.5);
        opacity: .3;
        position: relative;
        top: 37px;
    }
    
`
