import React, { useState } from 'react'
import gql from 'graphql-tag'
import { get } from 'ts-object-path'
import { useQuery } from '@apollo/react-hooks'
import {
  PartnerByIdClients,
  PartnerByIdClientsVariables
} from '../../_generated/PartnerByIdClients'

export const QUERY = gql`
query PartnerByIdClients($id: ID!, $offset: Int!, $limit: Int!){
  partners {
    byId(id: $id) {
      id
      clients(pagination: {offset: $offset, limit: $limit}) {
        id
        name
        email
        aggregated {
          balance
          equity
          deposited
          withdrawn
          fees
          currency
          profit
        }
      }
    }
  }
}`

export type Q = PartnerByIdClients
export type V = PartnerByIdClientsVariables
export type R = Q['partners']['byId']['clients']

export const parseData = (data: Q): R => {
  return get(data, p => p.partners.byId.clients)
}

export const parseMoreData = (prevResult: Q, fetchMoreResult: Q): Q => {
  if (!fetchMoreResult) return prevResult
  const clients = [...parseData(prevResult), ...parseData(fetchMoreResult)]
  const r: Q = JSON.parse(JSON.stringify(prevResult))
  r.partners.byId.clients = clients
  return r
}

type T = {
  partnerId: string
  limit: number
  offset: number
}

export function Use<T>({ partnerId: id, limit, offset = 0 }) {
  const [loadingMore, setLoadingMore] = useState(false)
  const query = useQuery<Q, V>(
    QUERY,
    {
      variables: {
        id,
        limit,
        offset,
      }
    }
  )

  const { loading, error, data, fetchMore } = query
  const parsecData = parseData(data)

  type TM = {
    limit: number
  }

  const more = ({limit = 4}) => {
    setLoadingMore(true)
    
    fetchMore({
      variables: {
        offset: parsecData.length,
        limit
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        setLoadingMore(false)
        return parseMoreData(prev, fetchMoreResult)
      }
    })

    return () =>{}
}

  return { loading, error, data: parsecData, fetchMore, parseMoreData, more, loadingMore }
}
