import gql from 'graphql-tag'
import { get } from 'ts-object-path'
import { useQuery } from '@apollo/react-hooks'
import {
  FeesChartByClientId_clientCharts,
  FeesChartByClientId_clientChartsVariables
} from '../../../_generated/FeesChartByClientId_clientCharts'
import { Precision } from '../../../_generated/globalTypes'

export const QUERY = gql`
query FeesChartByClientId_clientCharts($id: ID!, $precision: Precision!) {
  charts {
    byClientId(id: $id) {
      id: clientId
      fees(precision: $precision) {
        begin
        valuesCount
        values
        labels
        currency
      }
    }
  }
}`

export type Q = FeesChartByClientId_clientCharts
export type V = FeesChartByClientId_clientChartsVariables
export type R = Q['charts']['byClientId']['fees']

export const parseData = (data: Q): R => {
  return get(data, p=> p.charts.byClientId.fees)
}

export function Use(clientId: string) {
  const query = useQuery<Q, V>(
      QUERY,
      {
          variables: {
              id: clientId,
              precision: Precision.MONTH
          }
      }
  )

  const { loading, error, data } = query

  return { loading, error, data: parseData(data) }
}
