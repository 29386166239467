import * as React from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'

import { Use as PartnerData } from '../../data/partners/byId/details'
import { PartnerCardsListFragment, PartnerPosition } from '../../fragments/PartnerCardsList'
import { ClientCardsListFragment } from '../../fragments/ClientCardsList'
import { ErrorMessage } from '../../fragments/Timeline'
import { LoadingArea } from '../../components/LoadingArea'
import { SectionHeader } from '../../components/SectionHeader'

export const EmptyMessage = styled.div`
    padding: 10rem 2rem;
    font-size: 1.6rem;
    line-height: 2.6rem;
    color: #5d5d5d;

    @media (max-width: 1024px) {
        font-size: 1.4rem;
        line-height: 2.2rem;
    }

    @media (max-width: 600px) {
        font-size: 1.2rem;
        line-height: 1.8rem;
    }
`

const StructureStructurePage: React.SFC<{}> = () => {
    const { t } = useTranslation(localStorage.getItem('localeBranch'))
    const partnerId = localStorage.getItem('partnerId')
    const { loading, error, data } = PartnerData(partnerId)

    if (loading) return <LoadingArea size={400} />
    if (error) return <ErrorMessage><span>⚠</span> {t('error.error')}</ErrorMessage>

    const { counts: { sbibs, bibs, ibs, clients } } = data

    if (!sbibs && !bibs && !ibs && !clients) return <EmptyMessage>You currently have no clients.<br />As soon as some clients open their accounts, you’ll see them here.</EmptyMessage>

    return <>
        {sbibs > 0 && <>
            <SectionHeader>
                <h2><em>{t(`ysbip.header`)} <span>{sbibs}</span></em></h2>
                <p>{t(`ysbip.subheader`)}</p>
            </SectionHeader>
            <PartnerCardsListFragment partnerId={partnerId} position={PartnerPosition.SBIB} count={sbibs}></PartnerCardsListFragment>
        </>}

        {bibs > 0 && <>
            <SectionHeader>
                <h2><em>{t(`ybip.header`)} <span>{bibs}</span></em></h2>
                <p>{t(`ybip.subheader`)}</p>
            </SectionHeader>
            <PartnerCardsListFragment partnerId={partnerId} position={PartnerPosition.BIB} count={bibs}></PartnerCardsListFragment>
        </>}

        {ibs > 0 && <>
            <SectionHeader>
                <h2><em>{t(`yip.header`)} <span>{ibs}</span></em></h2>
                <p>{t(`yip.subheader`)}</p>
            </SectionHeader>
            <PartnerCardsListFragment partnerId={partnerId} position={PartnerPosition.IB} count={ibs}></PartnerCardsListFragment>

        </>}

        {clients > 0 && <>
            <SectionHeader>
                <h2><em>{t(`yclient.header`)} <span>{clients}</span></em></h2>
                <p>{t(`yclient.subheader`)}</p>
            </SectionHeader>
            <ClientCardsListFragment partnerId={partnerId} limit={8} count={clients}></ClientCardsListFragment>
        </>}
    </>
}

export { StructureStructurePage }
