/** @jsx nativeEvents */
import nativeEvents from '../native-events'

import * as React from 'react'
import styled from '@emotion/styled'
import { useTranslation, Trans } from 'react-i18next'
import { useMutation } from '@apollo/react-hooks'

import '@vaadin/vaadin-checkbox/vaadin-checkbox.js'

import { NotificationBar } from '../components/timeline/NotificationBar'

import {
    Use as UserData,
    TOGGLE_WITHDRAWAL_NOTIFICATIONS_MUTATION,
    QToggleWithdrawal,
    VToggleWithdrawal,
    TOGGLE_DIGEST_MUTATION,
    QToggleDigest,
    VToggleDigest
} from '../data/users/me'

type T = {}

export const TimelineNotificationsBarFragment: React.FunctionComponent<T> = () => {
    const { t } = useTranslation(localStorage.getItem('localeBranch'))
    const { loading, error, data: user } = UserData()

    const [toggleWithdrawal] = useMutation<QToggleWithdrawal, VToggleWithdrawal>(TOGGLE_WITHDRAWAL_NOTIFICATIONS_MUTATION)
    const [toggleDigest] = useMutation<QToggleDigest, VToggleDigest>(TOGGLE_DIGEST_MUTATION)

    const onToggleWithdrawal = ev => {
        toggleWithdrawal({
            variables: {
                subscribe: ev.currentTarget.checked
            }
        })
        return false
    }

    const onToggleDigest = ev => {
        toggleDigest({
            variables: {
                subscribe: ev.currentTarget.checked
            }
        })
        return false
    }

    return <NotificationBar>
        <strong>{t('timeline.notifications.header')}</strong>
        {!loading && <React.Fragment>
            <vaadin-checkbox
                _onChange={onToggleWithdrawal}
                checked={user.isSubscribedToWithdrawalRequests ? 'checked' : undefined}
            ><small>{t('timeline.notifications.activityDigest')}</small></vaadin-checkbox>
            <vaadin-checkbox
                _onChange={onToggleDigest}
                checked={user.isSubscribedToDigest ? 'checked' : undefined}
            ><small>{t('timeline.notifications.withdrawalNotification')}</small></vaadin-checkbox>
        </React.Fragment>}
    </NotificationBar>
}
