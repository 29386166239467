import * as React from 'react'
import { Route, NavLink } from 'react-router-dom'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

import { Router } from '../../router'
import { StructureStructurePage } from './StructureStructure'
import { StructureDataPage } from './StructureData'
import { StructureTimelinePage } from './StructureTimeline'
import { TabMenu } from '../../components/TabMenu'

const StructurePage: React.FunctionComponent<{}> = () => {
    const { t } = useTranslation(localStorage.getItem('localeBranch'))

    return <>
        <Header>{t('structure.header')}</Header>
        <Tabs>
            <li><NavLink exact to="/structure">{t('structure.tabs.structure')}</NavLink></li>
            <li><NavLink exact to="/structure/data">{t('structure.tabs.data')}</NavLink></li>
            <li><NavLink exact to="/structure/timeline">{t('structure.tabs.timeline')}</NavLink></li>
        </Tabs>
        <PageContainer>
            <Router>
                <Route exact path='/structure' component={StructureStructurePage}></Route>
                <Route exact path='/structure/data' component={StructureDataPage}></Route>
                <Route exact path='/structure/timeline' component={StructureTimelinePage}></Route>
            </Router>
        </PageContainer>
    </>
}

export { StructurePage }

export const PageContainer = styled.div`
    background: white;
    padding-bottom: 2rem;
`
export const Tabs = styled(TabMenu)`
    justify-content: center;
    box-shadow: 0 7px 10px rgba(0,0,0,.1);
    position: relative;

    a {
        //flex: 0.17 1 0;
        display: block;
        padding: 1rem;
        background: #fff;
        color: rgba(74,74,74,.76);
        font-family: Aventir medium,arial,sans-serif;
        font-size: 16px;
        text-align: center;
        border-style: solid;
        border-color: transparent;
        position: relative;
        outline: none;
        transition: all .15s linear;

        &:hover {
            color: #4a4a4a;
        }

        &.active {
            color: #9d38da;

            &:before {
                content: "";
                display: block;
                width: 100%;
                height: 3px;
                position: absolute;
                bottom: -1px;
                left: 0;
                background: #8505d4;
                z-index: 5;
            }
        }

    }

`

const Header = styled.h1`
    font-family: Aventir black,Arial,sans-serif;
    font-size: 1.6rem;
    line-height: 5rem;

    @media (max-width: 1024px) {
        padding: 0 2rem;
    }
`
