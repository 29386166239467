import gql from 'graphql-tag'
import { get } from 'ts-object-path'
import { useQuery} from '@apollo/react-hooks'
import { UserByMe } from '../_generated/UserByMe'
import { UserToggleWithdrawalNotifications, UserToggleWithdrawalNotificationsVariables } from '../_generated/UserToggleWithdrawalNotifications'
import { UserToggleDigest, UserToggleDigestVariables } from '../_generated/UserToggleDigest'


export const QUERY = gql`
query UserByMe {
  users {
    byMe {
      id
      partnerId
      email
      isAdmin
      isSubscribedToWithdrawalRequests
      isSubscribedToDigest
    }
  }
}`

export type Q = UserByMe
export type R = Q['users']['byMe']

export const parseData = (data: Q): R => {
  return get(data, p => p.users.byMe)
}

export function Use() {
  const query = useQuery<Q>(QUERY)

  const { loading, error, data } = query

  return { loading, error, data: parseData(data) }
}

export const TOGGLE_WITHDRAWAL_NOTIFICATIONS_MUTATION = gql`
mutation UserToggleWithdrawalNotifications($subscribe: Boolean!) {
  user {
    toggleWithdrawalNotifications(subscribe: $subscribe) {
      id,
      isSubscribedToWithdrawalRequests
    }
  }
}`

export type QToggleWithdrawal = UserToggleWithdrawalNotifications
export type VToggleWithdrawal = UserToggleWithdrawalNotificationsVariables

export const TOGGLE_DIGEST_MUTATION = gql`
mutation UserToggleDigest($subscribe: Boolean!) {
  user {
    toggleDigest(subscribe: $subscribe) {
      id,
      isSubscribedToDigest
    }
  }
}`

export type QToggleDigest = UserToggleDigest
export type VToggleDigest = UserToggleDigestVariables
