import React, { Suspense } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hooks'
import { ApolloProvider } from 'react-apollo'
import createClient from '../apollo-client'
import AuthDataProvider from '../auth-provider'
import { IndexPage } from './Index'
import TagManager from 'react-gtm-module'

const { client } = createClient('https://api2.purple-trading.com/v1/graphql')

if (!localStorage.getItem('localeBranch')) {
  localStorage.setItem('localeBranch', 'original')
}

export const App = (props: any) => {
  TagManager.initialize({
    gtmId: 'GTM-MVBFJPW',
  })
  return (
    <ApolloProvider client={client}>
      <ApolloHooksProvider client={client}>
        <AuthDataProvider>
          <BrowserRouter>
            <Suspense fallback={<div></div>}>
              <Switch>
                <Route exact path="*" component={IndexPage} />
              </Switch>
            </Suspense>
          </BrowserRouter>
        </AuthDataProvider>
      </ApolloHooksProvider>
    </ApolloProvider>
  )
}
