import gql from 'graphql-tag'
import { get } from 'ts-object-path'
import { useQuery } from '@apollo/react-hooks'
import {
  WithdrawalsChartByAccountId_accountsCharts,
  WithdrawalsChartByAccountId_accountsChartsVariables
} from '../../../_generated/WithdrawalsChartByAccountId_accountsCharts'
import { Precision } from '../../../_generated/globalTypes'

export const QUERY = gql`
query WithdrawalsChartByAccountId_accountsCharts($id: ID!, $precision: Precision!) {
  charts {
    byAccountId(id: $id) {
      id: accountId
      withdrawals(precision: $precision) {
        begin
        valuesCount
        values
        labels
        currency
      }
    }
  }
}`

export type Q = WithdrawalsChartByAccountId_accountsCharts
export type V = WithdrawalsChartByAccountId_accountsChartsVariables
export type R = Q['charts']['byAccountId']['withdrawals']

export const parseData = (data: Q): R => {
  return get(data, p => p.charts.byAccountId.withdrawals)
}

export function Use(accountId: string) {
  const query = useQuery<Q, V>(
      QUERY,
      {
          variables: {
              id: accountId,
              precision: Precision.MONTH
          }
      }
  )

  const { loading, error, data } = query

  return { loading, error, data: parseData(data) }
}
