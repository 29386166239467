import { useState } from 'react'
import gql from 'graphql-tag'
import { get } from 'ts-object-path'
import { useQuery } from '@apollo/react-hooks'
import {
  TransactionsTableByAccountId,
  TransactionsTableByAccountIdVariables
} from '../../../../_generated/TransactionsTableByAccountId'

export const QUERY = gql`
query TransactionsTableByAccountId($id: ID!, $offset: Int!, $limit: Int!) {
  accounts {
    byId(id: $id) {
      id
      number
      currency
      history {
        transactions(pagination: {offset: $offset, limit: $limit}) {
          date
          amount
          type
          comment
        }
      }
    }
  }
}`

export type Q = TransactionsTableByAccountId
export type V = TransactionsTableByAccountIdVariables
export type R = Q['accounts']['byId']['history']['transactions']  // TODO mozna doresit typ `currency`

export const parseData = (data: Q): R => {
  const accounts = get(data, p => p.accounts)
  if (accounts) {
    const transactions: R = JSON.parse(JSON.stringify(accounts.byId.history.transactions))
    const currency = accounts.byId.currency
    return transactions.map(transaction => Object.assign({}, transaction, {currency}))
  }
}

export const parseMoreData = (prevResult: Q, fetchMoreResult: Q): Q => {
  if (!fetchMoreResult) return prevResult
  const transactions = [...parseData(prevResult), ...parseData(fetchMoreResult)]
  const r: Q = JSON.parse(JSON.stringify(prevResult))
  r.accounts.byId.history.transactions = transactions
  return r
}


type T = {
  accountId: string
  limit: number
  offset: number
}

export function Use<T>({ accountId: id, limit, offset = 0 }) {
  const [loadingMore, setLoadingMore] = useState(false)
  const query = useQuery<Q, V>(
    QUERY,
    {
      variables: {
        id,
        limit,
        offset,
      }
    }
  )

  const { loading, error, data, fetchMore } = query
  const parsecData = parseData(data)

  type TM = {
    limit: number
  }

  const more = ({limit = 4}) => {
    setLoadingMore(true)
    
    fetchMore({
      variables: {
        offset: parsecData.length,
        limit
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        setLoadingMore(false)
        return parseMoreData(prev, fetchMoreResult)
      }
    })

    return () =>{}
}

  return { loading, error, data: parsecData, fetchMore, parseMoreData, more, loadingMore }
}
