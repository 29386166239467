import styled from '@emotion/styled'

export const Table = styled.table`
    width: 100%;
    table-layout: auto;

    tr:nth-of-type(even) {
        background-color: #efefef;
    }

    thead {
        text-align: left;
        border-bottom: 1px solid #ddd;

        td {
            padding: 11px 5px;
            color: #4a4a4a;
            font-size: 13px;
            line-height: 15px;
            font-weight: bold;

            &:first-child {
                padding-left: 2rem;
            }
            &:last-child {
                padding-right: 2rem;
            }
        }
    }

    tbody {
        tr {
            border-bottom: 1px solid #ddd;
        }

        td {
            padding: 11px 5px;
            color: #4a4a4a;
            font-size: 13px;
            line-height: 15px;

            &:first-child {
                padding-left: 2rem;
            }
            &:last-child {
                padding-right: 2rem;
            }
        }
    }

    .first {
        background: yellow;
    }

    td.right {
        text-align: right;
    }
`

export const NoItemsMessage = styled.div`
    font-size: 1.6rem;
    padding: 2rem;
`
