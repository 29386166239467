import * as React from 'react'
import styled from '@emotion/styled'
import { simpleId } from '../../utils'
import SvgIcon from '../../components/SvgIcon'

export const TRow = styled.div`
    border: 1px solid #e5e5e5;
    font-size: 0.9rem;
    margin-top: 1rem;
    position: relative;
    line-height: 1.1rem;

    @media (max-width: 750px) {
        margin-left: 40px;
    }
    @media (min-width: 750px) {
        margin-left: auto;
        margin-right: 40px;
        max-width: 430px;
    }

    a {
        color: #9d38da;
        text-decoration: none;
        border-bottom: 1px solid #e5e5e5;

        &:hover {
            border-bottom: 1px solid #9d38da;
        }
    }

    ul {
        padding: 10px 15px 10px 35px;
    }

    ._money {
      font-weight: bold;
    }

    strong {
        font-weight: bold;

        div {
            display: inline-block;

            span {
                text-transform: uppercase;
            }
        }
    }

    span {
        text-transform: lowercase;
    }

    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        width: 40px;
        height: 50%;
        top: 0;
        border-bottom: 1px dotted silver;

        @media (max-width: 750px) {
            left: -40px;
        }
        @media (min-width: 750px) {
            right: -40px;
        }
    }
`

const TSum = styled.div`
    background: #f5f5f5;
    padding: 10px 15px 10px 35px;
`

const TIcon = styled(SvgIcon)`
    position: absolute;
    left: 10px;
`

export const TimelineRow: React.FunctionComponent<any> = ({ icon, items, singleRowMessageFnc, sumMessage, sumRowMessageFnc }) => {
    return <>{items.length > 0 &&
        <TRow>
            {items.length === 1 && <div>
                <ul><li><TIcon size={18}>{icons[icon]}</TIcon> {singleRowMessageFnc(items[0])}</li></ul>
            </div>}
            {items.length > 1 && <>
                <TSum>
                    <TIcon size={18}>{icons[icon]}</TIcon>
                    <strong>{items.length}</strong> {sumMessage} 
                </TSum>
                <ul>{items.map((r, i) =>
                    <li key={simpleId()}>{sumRowMessageFnc(r, i)}</li>
                )}</ul>
            </>}
        </TRow>
    }</>
}

const icons = {
  deposit: <g>
    <path d="M12.001,16.5c-0.088,0-0.175-0.016-0.26-0.047c-0.008-0.003-0.015-0.005-0.022-0.008c-0.096-0.039-0.179-0.094-0.249-0.165
      L6.971,11.78c-0.142-0.141-0.22-0.33-0.22-0.53s0.078-0.389,0.22-0.53c0.141-0.142,0.33-0.22,0.53-0.22s0.389,0.078,0.53,0.22
      l3.22,3.22V3.75c0-0.414,0.336-0.75,0.75-0.75s0.75,0.336,0.75,0.75v10.189l3.22-3.22c0.142-0.142,0.33-0.22,0.53-0.22
      s0.389,0.078,0.53,0.22c0.142,0.141,0.22,0.33,0.22,0.53s-0.078,0.389-0.22,0.53l-4.5,4.5c-0.07,0.07-0.153,0.125-0.246,0.164
      c-0.008,0.003-0.017,0.007-0.026,0.01C12.174,16.485,12.088,16.5,12.001,16.5z"/>
    <path d="M3.751,21c-2.068,0-3.75-1.682-3.75-3.75v-1.5c0-0.414,0.336-0.75,0.75-0.75s0.75,0.336,0.75,0.75v1.5
      c0,1.241,1.009,2.25,2.25,2.25h16.5c1.241,0,2.25-1.009,2.25-2.25v-1.5c0-0.414,0.336-0.75,0.75-0.75s0.75,0.336,0.75,0.75v1.5
      c0,2.068-1.682,3.75-3.75,3.75H3.751z"/>
  </g>,
  withdrawal: <g>
    <path d="M12.001,16.5c-0.414,0-0.75-0.336-0.75-0.75V5.561l-3.22,3.22C7.89,8.922,7.701,9,7.501,9s-0.389-0.078-0.53-0.22
      c-0.142-0.141-0.22-0.33-0.22-0.53s0.078-0.389,0.22-0.53L11.47,3.22c0.07-0.07,0.152-0.125,0.245-0.163
      c0.008-0.003,0.016-0.006,0.025-0.009C11.826,3.016,11.913,3,12.001,3c0.089,0,0.177,0.016,0.261,0.048
      c0.007,0.002,0.014,0.005,0.02,0.007c0.097,0.04,0.179,0.095,0.25,0.166l4.499,4.499c0.142,0.141,0.22,0.33,0.22,0.53
      s-0.078,0.389-0.22,0.53S16.701,9,16.501,9s-0.389-0.078-0.53-0.22l-3.22-3.22V15.75C12.751,16.164,12.415,16.5,12.001,16.5z"/>
    <path d="M3.751,21c-2.068,0-3.75-1.682-3.75-3.75v-1.5c0-0.414,0.336-0.75,0.75-0.75s0.75,0.336,0.75,0.75v1.5
      c0,1.241,1.009,2.25,2.25,2.25h16.5c1.241,0,2.25-1.009,2.25-2.25v-1.5c0-0.414,0.336-0.75,0.75-0.75s0.75,0.336,0.75,0.75v1.5
      c0,2.068-1.682,3.75-3.75,3.75H3.751z"/>
  </g>,
  strategy: <g>
    <path d="M2.25,21C1.009,21,0,19.991,0,18.75V5.25C0,4.009,1.009,3,2.25,3h19.5C22.991,3,24,4.009,24,5.25v13.5
      c0,1.241-1.009,2.25-2.25,2.25H2.25z M2.25,4.5C1.836,4.5,1.5,4.836,1.5,5.25v13.5c0,0.414,0.336,0.75,0.75,0.75h19.5
      c0.414,0,0.75-0.336,0.75-0.75V5.25c0-0.414-0.336-0.75-0.75-0.75H2.25z"/>
    <path d="M9.751,15.75c-0.037,0-0.074-0.003-0.111-0.008c-0.23-0.033-0.437-0.177-0.549-0.384l-2.224-4.096l-2.474,4.124
      C4.258,15.61,4.012,15.75,3.75,15.75c-0.136,0-0.27-0.037-0.386-0.107c-0.171-0.103-0.293-0.267-0.341-0.461
      s-0.019-0.396,0.084-0.568l3.15-5.25c0.135-0.225,0.376-0.365,0.63-0.365c0.008,0,0.037,0.001,0.045,0.001
      c0.252,0.005,0.499,0.155,0.627,0.391l2.371,4.367l2.29-2.29c0.142-0.142,0.33-0.22,0.53-0.22s0.389,0.078,0.53,0.22l2.353,2.353
      l3.993-5.989C19.766,7.625,19.999,7.5,20.25,7.5c0.148,0,0.292,0.044,0.416,0.126c0.167,0.111,0.281,0.281,0.32,0.477
      c0.039,0.196,0,0.396-0.112,0.563l-4.5,6.75c-0.125,0.187-0.325,0.308-0.55,0.33c-0.024,0.003-0.05,0.004-0.075,0.004
      c-0.197,0-0.39-0.08-0.53-0.22l-2.469-2.469l-2.47,2.47C10.138,15.672,9.95,15.75,9.751,15.75z"/>
  </g>,
  partner: <g>
    <path d="M17.25,24c-3.722,0-6.75-3.028-6.75-6.75s3.028-6.75,6.75-6.75S24,13.528,24,17.25S20.972,24,17.25,24z M17.25,12
      C14.355,12,12,14.355,12,17.25s2.355,5.25,5.25,5.25s5.25-2.355,5.25-5.25S20.145,12,17.25,12z"/>
    <path d="M17.25,21c-0.414,0-0.75-0.336-0.75-0.75V18h-2.25c-0.414,0-0.75-0.336-0.75-0.75s0.336-0.75,0.75-0.75h2.25v-2.25
      c0-0.414,0.336-0.75,0.75-0.75S18,13.836,18,14.25v2.25h2.25c0.414,0,0.75,0.336,0.75,0.75S20.664,18,20.25,18H18v2.25
      C18,20.664,17.664,21,17.25,21z"/>
    <path d="M6,7.5c-2.068,0-3.75-1.682-3.75-3.75S3.932,0,6,0s3.75,1.682,3.75,3.75S8.068,7.5,6,7.5z M6,1.5
      c-1.241,0-2.25,1.009-2.25,2.25S4.759,6,6,6s2.25-1.009,2.25-2.25S7.241,1.5,6,1.5z"/>
    <path d="M3.75,24c-0.387,0-0.708-0.29-0.746-0.675L2.321,16.5H0.75C0.336,16.5,0,16.164,0,15.75V13.5
      c0.001-2.142,1.156-4.136,3.014-5.202c0.889-0.511,1.9-0.785,2.926-0.794C5.959,7.501,5.979,7.5,6,7.5
      c0.013,0,0.041,0.002,0.061,0.004c2.122,0.024,4.092,1.177,5.145,3.011c0.1,0.174,0.126,0.376,0.074,0.569
      s-0.177,0.355-0.351,0.454c-0.113,0.065-0.242,0.1-0.373,0.1c0,0,0,0,0,0c-0.268,0-0.517-0.144-0.651-0.377
      c-0.672-1.171-1.832-1.97-3.155-2.194v5.183C6.75,14.664,6.414,15,6,15s-0.75-0.336-0.75-0.75V9.069
      c-0.528,0.087-1.027,0.264-1.49,0.53c-1.393,0.8-2.259,2.295-2.26,3.902V15H3c0.387,0,0.708,0.29,0.746,0.675L4.429,22.5h3.143
      l0.255-2.558c0.038-0.379,0.364-0.676,0.742-0.676c0.028,0,0.057,0.002,0.086,0.005c0.193,0.019,0.372,0.115,0.499,0.271
      c0.127,0.155,0.186,0.35,0.166,0.549l-0.323,3.233C8.958,23.71,8.637,24,8.25,24H3.75z"/>
  </g>,
  client: <g>
    <path d="M17.25,24c-3.722,0-6.75-3.028-6.75-6.75s3.028-6.75,6.75-6.75S24,13.528,24,17.25S20.972,24,17.25,24z M17.25,12
      C14.355,12,12,14.355,12,17.25s2.355,5.25,5.25,5.25s5.25-2.355,5.25-5.25S20.145,12,17.25,12z"/>
    <path d="M17.25,21c-0.414,0-0.75-0.336-0.75-0.75V18h-2.25c-0.414,0-0.75-0.336-0.75-0.75s0.336-0.75,0.75-0.75h2.25v-2.25
      c0-0.414,0.336-0.75,0.75-0.75S18,13.836,18,14.25v2.25h2.25c0.414,0,0.75,0.336,0.75,0.75S20.664,18,20.25,18H18v2.25
      C18,20.664,17.664,21,17.25,21z"/>
    <path d="M6,7.5c-2.068,0-3.75-1.682-3.75-3.75S3.932,0,6,0s3.75,1.682,3.75,3.75S8.068,7.5,6,7.5z M6,1.5
      c-1.241,0-2.25,1.009-2.25,2.25S4.759,6,6,6s2.25-1.009,2.25-2.25S7.241,1.5,6,1.5z"/>
    <path d="M3.75,24c-0.387,0-0.708-0.29-0.746-0.675L2.321,16.5H0.75C0.336,16.5,0,16.164,0,15.75V13.5
      c0.001-2.142,1.156-4.136,3.014-5.202C3.92,7.777,4.95,7.502,5.992,7.502c2.149,0,4.147,1.154,5.214,3.013
      c0.1,0.174,0.126,0.376,0.074,0.569s-0.177,0.355-0.351,0.454c-0.113,0.065-0.242,0.1-0.373,0.1c0,0,0,0,0,0
      c-0.268,0-0.517-0.144-0.651-0.377c-0.8-1.394-2.299-2.259-3.912-2.259c-0.781,0-1.553,0.206-2.233,0.597
      c-1.393,0.8-2.259,2.295-2.26,3.902V15H3c0.387,0,0.708,0.29,0.746,0.675L4.429,22.5h3.143l0.255-2.558
      c0.038-0.379,0.364-0.676,0.742-0.676c0.028,0,0.057,0.002,0.086,0.005c0.193,0.019,0.372,0.115,0.499,0.271
      c0.127,0.155,0.186,0.35,0.166,0.549l-0.323,3.233C8.958,23.71,8.637,24,8.25,24H3.75z"/>
  </g>
}
