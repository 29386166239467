import * as React from 'react'
import moment from 'moment'
import styled from '@emotion/styled'
import { Money } from '../Money'

const Tooltip = styled.div`
    padding: 10px;
    background: #522974ee;
    color: white;
  
    table {
      border: none;
    }
  
    .label {
      padding-bottom: 10px;
      font-weight: bold;
    }
  
    .name {
      font-size: 85%;
      opacity: 0.9;
      padding-right: 10px;
    }
  
    .value {
  
    }
`

type TooltipType = {
    payload?: [{
        name: string
        value: number
    }]
    label?: string[]
    labels?: any
    currency: string
}

export const CustomTooltip: React.SFC<TooltipType> = ({ payload, label, labels, currency }) => {
    return <Tooltip>
        <div className="label">{moment(label).format('MMM YYYY')}</div>
        <table><tbody>
            {payload.map(p => {
                return <tr key={p.name}>
                    <td>
                        <span className="name">{labels && labels[p.name] || p.name}</span>
                    </td>
                    <td>
                        <span className="value">
                            <Money value={p.value} currency={currency} />
                        </span>
                    </td>
                </tr>
            })}
        </tbody></table>
    </Tooltip>
}