import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { BigChartFragment } from '../../../fragments/BigChart'

import { Use as FeesChartData } from '../../../data/partners/byId/charts/fees'

const PartnerFeesChart: React.FunctionComponent<{ partnerId: string, tkey: string }> = ({ partnerId, tkey }) => {
    const { t } = useTranslation(localStorage.getItem('localeBranch'))

    return <>
        <BigChartFragment
            header={t(`${tkey}.data.charts.fees.header`)}
            desc={t(`${tkey}.data.charts.fees.desc`)}
            type="bar"
            cols={[{ key: 'value', color: '#07ada3', label: t('structure.data.charts.fees.header'), data: FeesChartData(partnerId), valueFnc: v => -v }]}
        />
    </>
}

export { PartnerFeesChart }
