import * as React from 'react'
import styled from '@emotion/styled'
import SvgIcon from '../../components/SvgIcon'

export const NotificationBar = styled.div`
    font-size: 0.9rem;
    background: rgba(0,0,0,.08);
    padding: 1rem 2rem;

    strong {
        font-weight: bold;
    }

    vaadin-checkbox {
        margin-left: 2rem;
        cursor: pointer;
    }

    @media (max-width: 800px) {
        strong, vaadin-checkbox {
            display: block;
        }

        vaadin-checkbox {
            margin-left: 0;
            padding-top: .5rem;
        }
    }

    
`
