/** @jsx nativeEvents */
import nativeEvents from '../native-events'

import styled from '@emotion/styled'
import * as React from 'react'
import { useRef, useState } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import '@vaadin/vaadin-button/vaadin-button.js'
import '@vaadin/vaadin-text-field/vaadin-email-field.js'
import '@vaadin/vaadin-text-field/vaadin-password-field.js'
import '@vaadin/vaadin-checkbox/vaadin-checkbox.js'

import { MUTATION, parseData, Q, V } from '../data/auth/login'
import { useAuthDataContext } from '../auth-provider'
import { LoginForm } from '../components/forms/Login'
import { LoadingOverlay } from '../components/LoadingOverlay'

const BRAND = 'pt'

export interface Props extends React.HTMLProps<HTMLDivElement> {
}

const LoginPage: React.FunctionComponent<{}> = () => {
    const { t } = useTranslation(localStorage.getItem('localeBranch'))
    const [login, { loading, data, error }] = useMutation<Q, V>(MUTATION)
    const { onLogin } = useAuthDataContext()
    const history = useHistory()

    const onSubmit = ({ email, password }) => {
        login({
            variables: {
                email,
                password
            }
        }).then((data) => {
            onLogin(parseData(data.data))
            history.push('/structure')
            //localStorage.setItem('token', parseData(data.data).idToken)
        })
        return false
    }

    return <div>
        <Logo><img src={`/img/${BRAND}/logo.png`} /></Logo>
        <Container>
            <h1>{t('login.header')}</h1>
            <p>{t('login.desc')}</p>

            <LoginForm onSubmit={onSubmit} />

            {loading && <LoadingOverlay />}
            {error && <ErrorContainer>{error.graphQLErrors.map(i => <div>{i.message}</div>)}</ErrorContainer>}
        </Container>
    </div>
}

export default LoginPage

export const Logo = styled.div`
    text-align: center;
    padding: 40px;
    height: 125px;

    img {
        max-height: 100%;
        height: 45px;
    }
`

export const Container = styled.div`
    max-width: 650px;
    background: #fff;
    color: #555459;
    border-radius: 0 0 3px 3px;
    padding: 14px 28px 28px;
    margin: 0 auto 40px;
    border: 1px solid rgba(0,0,0,.05);
    position: relative;

    h1 {
        padding: 20px 0;
        font-family: Aventir black,Arial,sans-serif;
        font-size: 28px;
    }

    strong {
        font-weight: bold;
    }

    form {
        display: flex;
        flex-direction: column;

        input {
            background-color: transparent !important;
        }
    }

    vaadin-checkbox {
        margin-top: var(--lumo-space-m);
        color: var(--lumo-secondary-text-color);
    }

    #submit {
        margin-top: var(--lumo-space-m);
        box-sizing: border-box;
        min-width: 90px;
        padding: 10px;
        position: relative;
        background-color: #fff;
        border: 1px solid hsla(240,4%,64%,.26);
        font-family: Aventir heavy,arial;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        color: #8746bd;
        box-shadow: 0 1px 0 #9e9ea6;
        outline: none;
        transition: all .25s linear;
        font-size: 1rem;
        height: 2.5rem;
        border-radius: 0;
    }

    /*
    vaadin-button#submit {
        margin-top: var(--lumo-space-m);
        cursor: pointer;
    }
    */
`

export const ErrorContainer = styled.div`
    margin-top: 1rem;
    color: #ff4238;
    background: #ff42381c;
    padding: 1rem;
    border-radius: var(--lumo-border-radius);
    font-weight: 500;
    font-size: var(--lumo-font-size-s);
    -webkit-font-smoothing: antialiased;
`
