import * as React from 'react'
import { Route, NavLink, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Router } from '../../router'
import { ClientStructurePage } from './ClientStructure'
import { ClientDataPage } from './ClientData'
import { StructureTimelinePage } from '../structure/StructureTimeline'
import { PageContainer, Tabs } from '../structure/Structure'
import { ClientHeaderFragment } from '../../fragments/ClientHeader'

export const ClientPage: React.SFC<{}> = () => {
    const { t } = useTranslation(localStorage.getItem('localeBranch'))
    let { clientId } = useParams()

    return <>
        <ClientHeaderFragment clientId={clientId} />

        <Tabs>
            <li><NavLink exact to={'/client/' + clientId}>{t('client.tabs.structure')}</NavLink></li>
            <li><NavLink exact to={'/client/' + clientId + '/data'}>{t('client.tabs.data')}</NavLink></li>
        </Tabs>

        <PageContainer>
            <Router>
                <Route exact path='/client/:clientId' component={ClientStructurePage}></Route>
                <Route exact path='/client/:clientId/data' component={ClientDataPage}></Route>
                <Route exact path='/client/:clientId/timeline' component={StructureTimelinePage}></Route>
            </Router>
        </PageContainer>
    </>
}
