
import * as React from 'react'
import { createContext, useState, useEffect, useMemo, useContext } from 'react'
//import someManager from '../some-manager'
import decode from 'jwt-decode'

export const checkAuth = () => {
  const token = localStorage.getItem('token')
  const refreshToken = localStorage.getItem('refreshToken')

  if (!token || !refreshToken) {
    return false
  }

  try {
    // { exp: 12903819203 }
    const { exp } = decode(token)

    if (exp < new Date().getTime() / 1000) {
      return false
    }

  } catch (e) {
    return false
  }

  return true
}

const refreshToken = () => {
  const refreshToken = localStorage.getItem('refreshToken')
}

const someManager = {
  getAuthData() {
    return {
      token: localStorage.getItem('token'),
      refreshToken: localStorage.getItem('refreshToken'),
      email: localStorage.getItem('loginEmail')
    }
  }
}


type IContextProps = any

const AuthDataContext = createContext({})

const initialAuthData = someManager.getAuthData()

const AuthDataProvider = props => {
  const [authData, setAuthData] = useState(initialAuthData)

  useEffect(() => {
    const currentAuthData = someManager.getAuthData()
    if (currentAuthData) {
      setAuthData(currentAuthData)
    }
  }, [])

  const onLogout = () => {
    setAuthData({ token: undefined, refreshToken: undefined, email: undefined })
    localStorage.removeItem('token')
    localStorage.removeItem('refreshToken')
  }

  const onLogin = loginData => {
    const data = decode(loginData.idToken)

    localStorage.setItem('token', loginData.idToken)
    localStorage.setItem('refreshToken', loginData.refreshToken)
    if (localStorage.getItem('rememberEmail')) {
      localStorage.setItem('loginEmail', data.email)
    }
    else {
      localStorage.removeItem('loginEmail')
    }

    setAuthData({
      token: loginData.idToken,
      refreshToken: loginData.refreshToken,
      email: data.email
    })
  }

  const authDataValue = useMemo(() => { return { ...authData, onLogin, onLogout } }, [authData])

  return <AuthDataContext.Provider value={authDataValue} {...props} />
}

export const useAuthDataContext: any = () => useContext(AuthDataContext)

export default AuthDataProvider
