import gql from 'graphql-tag'
import { get } from 'ts-object-path'
import { useQuery} from '@apollo/react-hooks'
import {
  WithdrawalsChartByPartnerId_partnersCharts,
  WithdrawalsChartByPartnerId_partnersChartsVariables
} from '../../../_generated/WithdrawalsChartByPartnerId_partnersCharts'
import { Precision } from '../../../_generated/globalTypes'

export const QUERY = gql`
query WithdrawalsChartByPartnerId_partnersCharts($id: ID!, $precision: Precision!) {
  charts {
    byPartnerId(id: $id) {
      id: partnerId
      withdrawals(precision: $precision) {
        begin
        valuesCount
        values
        labels
        currency
      }
    }
  }
}`

export type Q = WithdrawalsChartByPartnerId_partnersCharts
export type V = WithdrawalsChartByPartnerId_partnersChartsVariables
export type R = Q['charts']['byPartnerId']['withdrawals']

export const parseData = (data: Q): R => {
  return get(data, p=> p.charts.byPartnerId.withdrawals)
}

export function Use(partnerId: string) {
  const query = useQuery<Q, V>(
      QUERY,
      {
          variables: {
              id: partnerId,
              precision: Precision.MONTH
          }
      }
  )

  const { loading, error, data } = query

  return { loading, error, data: parseData(data) }
}
