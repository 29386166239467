import * as React from 'react'
import { ReferenceLine, BarChart, Line, LineChart, Cell, Bar, CartesianGrid, ResponsiveContainer, YAxis } from 'recharts'
import { positiveColor, negativeColor, gradientOffset, gradientHtml } from './utils'
import { simpleId } from '../../utils'


type ChartType = {
    id: any
    type: 'bar' | 'line'
    cols: any
    data: any
}

export const ChartSmall: React.SFC<ChartType> = ({ id, type, cols, data }) => {
    const { off, max, min } = gradientOffset(data)
    const ChartElem = type == 'bar' ? BarChart : LineChart
    return <>
        <div>
            <ResponsiveContainer width="100%" height={100}>
                <ChartElem data={data}>
                    <CartesianGrid vertical={false} stroke="#fcfcfc" />

                    {
                        min >= 0 &&  // Only green (+)
                        <defs>
                            <linearGradient id={`splitColor${id}`} x1="0" y1="0" x2="0" y2="1">
                                <stop offset={0} stopColor={positiveColor} stopOpacity={1} />
                            </linearGradient>
                        </defs>
                    }
                    {
                        max <= 0 &&  // Only red (-)
                        <defs>
                            <linearGradient id={`splitColor${id}`} x1="0" y1="0" x2="0" y2="1">
                                <stop offset={0} stopColor={negativeColor} stopOpacity={1} />
                            </linearGradient>
                        </defs>
                    }
                    {
                        min < 0 &&  // Green and Red (+-)
                        <defs>
                            <linearGradient id={`splitColor${id}`} x1="0" y1="0" x2="0" y2="1">
                                <stop offset={off} stopColor={positiveColor} stopOpacity={1} />
                                <stop offset={off} stopColor={negativeColor} stopOpacity={1} />
                            </linearGradient>
                        </defs>
                    }


                    <YAxis type="number" domain={[dataMin => dataMin < 0 ? dataMin : 0, dataMax => dataMax > 0 ? dataMax : 1]} hide />
                    <ReferenceLine y={0} stroke="gray" strokeDasharray="1" />

                    {
                        type === 'bar'
                            ? cols.map(col => <Bar type="monotone" dataKey={col.key} fill={`url(#colorUv${col.key})`} key={simpleId}>
                                {data.map((entry, index/*, x, y*/) => {
                                    return <Cell key={`cell-${index}`} fill={entry[col.key] > 0 ? positiveColor : negativeColor} />
                                })
                                }
                            </Bar>)
                            : cols.map(col => <Line type="monotone" dataKey={col.key} stroke={`url(#splitColor${id})`} strokeWidth={2} dot={false} key={simpleId} />)
                    }
                </ChartElem>
            </ResponsiveContainer>
        </div>
    </>
}
