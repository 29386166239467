import { useState } from 'react'
import gql from 'graphql-tag'
import { get } from 'ts-object-path'
import { useQuery } from '@apollo/react-hooks'
import {
  OpenTradesTableByAccountId,
  OpenTradesTableByAccountIdVariables
} from '../../../../_generated/OpenTradesTableByAccountId'

export const QUERY = gql`
query OpenTradesTableByAccountId($id: ID!, $offset: Int!, $limit: Int!) {
  accounts {
    byId(id: $id) {
      id
      number
      currency
      history {
        trades(state: OPEN, pagination: {offset: $offset, limit: $limit}) {
          openDate
          type
          volume
          sl
          tp
          openPrice
          profit
          instrument
        }
      }
    }
  }
}`

export type Q = OpenTradesTableByAccountId
export type V = OpenTradesTableByAccountIdVariables
export type R = Q['accounts']['byId']['history']['trades']

export const parseData = (data: Q): R => {
  const accounts = get(data, p => p.accounts)
  if (accounts) {
    const trades: R = JSON.parse(JSON.stringify(accounts.byId.history.trades))
    const currency = accounts.byId.currency
    return trades.map(trade => Object.assign({}, trade, {currency}))
  }
}

export const parseMoreData = (prevResult: Q, fetchMoreResult: Q): Q => {
  if (!fetchMoreResult) return prevResult
  const trades = [...parseData(prevResult), ...parseData(fetchMoreResult)]
  const r: Q = JSON.parse(JSON.stringify(prevResult))
  r.accounts.byId.history.trades = trades
  return r
}


type T = {
  accountId: string
  limit: number
  offset: number
}

export function Use<T>({ accountId: id, limit, offset = 0 }) {
  const [loadingMore, setLoadingMore] = useState(false)
  const query = useQuery<Q, V>(
    QUERY,
    {
      variables: {
        id,
        limit,
        offset,
      }
    }
  )

  const { loading, error, data, fetchMore } = query
  const parsecData = parseData(data)

  type TM = {
    limit: number
  }

  const more = ({limit = 4}) => {
    setLoadingMore(true)
    
    fetchMore({
      variables: {
        offset: parsecData.length,
        limit
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        setLoadingMore(false)
        return parseMoreData(prev, fetchMoreResult)
      }
    })

    return () =>{}
}

  return { loading, error, data: parsecData, fetchMore, parseMoreData, more, loadingMore }
}
