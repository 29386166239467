import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { Use as ClientData } from '../data/clients/byId/details'
import { Money } from '../components/Money'
import { Tabs, SkeletonTabs } from './PartnerAggregatedHeader'

export const ClientAggregatedHeaderFragment: React.FunctionComponent<{ clientId: string }> = ({ clientId }) => {
    const { t } = useTranslation(localStorage.getItem('localeBranch'))
    const { loading, error, data } = ClientData(clientId)

    if (loading) return <SkeletonTabs>
        <li><h3>{t('client.data.overview.balance')}</h3><div><span></span></div></li>
        <li><h3>{t('client.data.overview.equity')}</h3><div><span></span></div></li>
        <li><h3>{t('client.data.overview.profit')}</h3><div><span></span></div></li>
    </SkeletonTabs>

    if (error) return <div>error!!</div>

    const { aggregated: { balance, equity, profit, currency } } = data

    return <Tabs>
        <li>
            <h3>{t('client.data.overview.balance')}</h3>
            <div><Money value={balance} currency={currency} /></div>
        </li>
        <li>
            <h3>{t('client.data.overview.equity')}</h3>
            <div><Money value={equity} currency={currency} /></div>
        </li>
        <li>
            <h3>{t('client.data.overview.profit')}</h3>
            <div><Money value={profit} currency={currency} /></div>
        </li>
    </Tabs>
}
