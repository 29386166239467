import gql from 'graphql-tag'
import { ExecutionResult } from 'graphql'
import {
  ResetPassword,
  ResetPasswordVariables,
} from '../_generated/ResetPassword'

export const MUTATION = gql`
mutation ResetPassword($email: String!, $code: String!, $password: String!) {
  user {
    confirmForgotPassword(email: $email, code: $code, password: $password)
  }
}`

export type Q = ResetPassword
export type V = ResetPasswordVariables
type _R = Q['user']['confirmForgotPassword']
export type R = _R | ExecutionResult<_R>

export const parseData = (data: Q): R => {
  return data.user.confirmForgotPassword
}
