import * as React from 'react'
import { Route, NavLink, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Router } from '../../router'
import { PartnerStructurePage } from './PartnerStructure'
import { PartnerDataPage } from './PartnerData'
import { PartnerTimelinePage } from '../partner/PartnerTimeline'
import { PageContainer, Tabs } from '../structure/Structure'
import { PartnerHeaderFragment } from '../../fragments/PartnerHeader'


const PartnerPage: React.SFC<{}> = () => {
    const { t } = useTranslation(localStorage.getItem('localeBranch'))
    let { partnerId } = useParams()

    return <>
        <PartnerHeaderFragment partnerId={partnerId} />

        <Tabs>
            <li><NavLink exact to={'/partner/' + partnerId}>{t('partner.tabs.structure')}</NavLink></li>
            <li><NavLink exact to={'/partner/' + partnerId + '/data'}>{t('partner.tabs.data')}</NavLink></li>
            <li><NavLink exact to={'/partner/' + partnerId + '/timeline'}>{t('partner.tabs.timeline')}</NavLink></li>
        </Tabs>

        <PageContainer>
            <Router>
                <Route exact path='/partner/:partnerId' component={PartnerStructurePage}></Route>
                <Route exact path='/partner/:partnerId/data' component={PartnerDataPage}></Route>
                <Route exact path='/partner/:partnerId/timeline' component={PartnerTimelinePage}></Route>
            </Router>
        </PageContainer>
    </>
}

export { PartnerPage }
