import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { BigChartFragment } from '../../../fragments/BigChart'

import { Use as BalanceChartData } from '../../../data/partners/byId/charts/balance'

const PartnerBalanceChart: React.FunctionComponent<{ partnerId: string, tkey: string }> = ({ partnerId, tkey }) => {
    const { t } = useTranslation(localStorage.getItem('localeBranch'))

    return <>
        <BigChartFragment
            header={t(`${tkey}.data.charts.balance.header`)}
            desc={t(`${tkey}.data.charts.balance.desc`)}
            type="area"
            cols={[{ key: 'value', color: '#07ada3', label: 'Balance', data: BalanceChartData(partnerId) }]}
        />
    </>
}

export { PartnerBalanceChart }
