import * as React from 'react'
//import * as classNames from 'classnames'

export type StandardProps<C, Removals extends keyof C = never> =
    & Omit<C & {}, Removals>

export interface SvgIconProps extends StandardProps<React.SVGProps<SVGSVGElement>> {
    color?: string //PropTypes.Color | 'disabled' | 'error';
    size?: number | string
    title?: string
    viewBox?: string
}


const SvgIcon: React.SFC<SvgIconProps> = (props) => {
    const {
        children,
        //className: classNameProp,
        color,
        size,
        viewBox,
        ...other
    } = props
    /*
        const className = classNames(
            classNameProp,
        )
    */
    return (
        <svg
            //className={className}
            focusable="false"
            viewBox={viewBox}
            aria-hidden="true"
            width={size + 'px'}
            height={size + 'px'}
            {...other}
        >
            {children}
            {/*
      <style jsx>{`{
        display: inline-block;
        height: ${size}px;
        width: ${size}px;
        fill: ${color};
        user-select: none;
        flex-shrink: 0;
      }`}</style>
    */}
        </svg>
    )
}

SvgIcon.defaultProps = {
    color: 'inherit',
    viewBox: '0 0 24 24',
    size: 24,
}

export default SvgIcon
