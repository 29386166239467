import gql from 'graphql-tag'
import { ExecutionResult } from 'graphql'
import {
  Login,
  LoginVariables,
} from '../_generated/Login'

export const MUTATION = gql`
mutation Login($email: String!, $password: String!) {
  auth {
    authenticate(email: $email, password: $password) {
      idToken
      refreshToken
    }
  }
}`

export type Q = Login
export type V = LoginVariables
type _R = Q['auth']['authenticate']
export type R = _R | ExecutionResult<_R>

export const parseData = (data: Q): R => {
  return data.auth.authenticate
}
