import styled from '@emotion/styled'
import * as React from 'react'
import { useRef, useState } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import '@vaadin/vaadin-button/vaadin-button.js'
import '@vaadin/vaadin-text-field/vaadin-email-field.js'
import '@vaadin/vaadin-text-field/vaadin-password-field.js'
import '@vaadin/vaadin-checkbox/vaadin-checkbox.js'

import { MUTATION, parseData, Q, V } from '../data/auth/forgotPassword'
import { useAuthDataContext } from '../auth-provider'
import { ForgotForm } from '../components/forms/Forgot'
import { LoadingOverlay } from '../components/LoadingOverlay'

import { Logo, Container, ErrorContainer } from './Login'

const BRAND = 'pt'

export interface Props extends React.HTMLProps<HTMLDivElement> {
}

const ForgotPage: React.FunctionComponent<{}> = () => {
    const { t } = useTranslation(localStorage.getItem('localeBranch'))
    const [forgot, { loading, data, error }] = useMutation<Q, V>(MUTATION)
    const [submited, setSubmited] = useState(false)
    const { onLogin } = useAuthDataContext()
    const history = useHistory()

    const onSubmit = ({ email, password }) => {
        forgot({
            variables: {
                email
            }
        }).then((data) => {
            setSubmited(true)
        })
        return false
    }

    return <div>
        <Logo><img src={`/img/${BRAND}/logo.png`} /></Logo>
        <Container>
            <h1>{t('forgot.header')}</h1>

            {!submited
                ? <>
                    <p>{t('forgot.desc')}</p>
                    <ForgotForm onSubmit={onSubmit} />
                </>
                : <p>{t('forgot.finish')}</p>
            }

            {loading && <LoadingOverlay />}
            {error && <ErrorContainer>{error.graphQLErrors.map(i => <div>{i.message}</div>)}</ErrorContainer>}
        </Container>
    </div>
}

export default ForgotPage
