import * as React from 'react'
import styled from '@emotion/styled'

const MoneyContainer = styled.div`
    display: inline-block;

    ._currency {
        font-size: 80%;
        opacity: .6;
        text-transform: uppercase;
    }
`

type MoneyType = {
    value: number
    currency: string
    fixed?: number
}

const nf = Intl.NumberFormat()

const Money: React.SFC<MoneyType> = ({ value, currency, fixed = 2 }) => {

    const num = (val: any, fixed: number): number => {
        let n = +Number.parseFloat(val).toFixed(fixed)
        return n === 0 ? 0 : n // remove minus zero -0
    }

    return <>
        <MoneyContainer className="_money">
            <span>{nf.format(num(value, fixed))}</span>
            <span className="_currency"> {currency}</span>
        </MoneyContainer>
    </>
}

export { Money }
