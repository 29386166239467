import gql from 'graphql-tag'
import { get } from 'ts-object-path'
import { useQuery} from '@apollo/react-hooks'
import {
  PartnerByIdDetails,
  PartnerByIdDetailsVariables
} from '../../_generated/PartnerByIdDetails'

export const QUERY = gql`
query PartnerByIdDetails($id: ID!){
  partners {
    byId(id: $id) {
      id
      name
      position
      ancestors {
        id
        name
        position
      }
      aggregated {
        balance
        equity
        deposited
        withdrawn
        currency
        profit
      }
      counts {
        sbibs
        bibs
        ibs
        clients
      }
    }
  }
}`

export type Q = PartnerByIdDetails
export type V = PartnerByIdDetailsVariables
export type R = Q['partners']['byId']


export const parseData = (data: Q): R => {
  return get(data, p => p.partners.byId)
}

export function Use(partnerId: string) {
  const query = useQuery<Q, V>(
      QUERY,
      {
          variables: {
              id: partnerId,
          }
      }
  )

  const { loading, error, data } = query

  return { loading, error, data: parseData(data) }
}
