import styled from '@emotion/styled'
import * as React from 'react'
import { useRef, useState } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import '@vaadin/vaadin-button/vaadin-button.js'
import '@vaadin/vaadin-text-field/vaadin-email-field.js'
import '@vaadin/vaadin-text-field/vaadin-password-field.js'
import '@vaadin/vaadin-checkbox/vaadin-checkbox.js'

import { MUTATION, parseData, Q, V, Language } from '../data/auth/signUp'
import { useAuthDataContext } from '../auth-provider'
import { RegisterForm } from '../components/forms/Register'
import { LoadingOverlay } from '../components/LoadingOverlay'

import { Logo, Container, ErrorContainer } from './Login'

const BRAND = 'pt'

export interface Props extends React.HTMLProps<HTMLDivElement> {
}

const RegisterPage: React.FunctionComponent<{}> = () => {
    const { t } = useTranslation(localStorage.getItem('localeBranch'))
    const [signUp, { loading, data, error }] = useMutation<Q, V>(MUTATION)
    const [submited, setSubmited] = useState(false)
    const { onLogin } = useAuthDataContext()
    const history = useHistory()

    const onSubmit = ({ email, password }) => {
        signUp({
            variables: {
                language: 'en' as Language,
                email,
                password
            }
        }).then((data) => {
            setSubmited(true)
            //localStorage.setItem('token', parseData(data.data).idToken)
        })
        return false
    }

    return <>
        <Logo><img src={`/img/${BRAND}/logo.png`} /></Logo>
        <Container>
            <h1>{t('register.header')}</h1>

            {!submited
                ? <>
                    <p>{t('register.desc')}</p>
                    <RegisterForm onSubmit={onSubmit} />
                </>
                : <p>{t('register.finish')}</p>
            }

            {loading && <LoadingOverlay />}
            {error && <ErrorContainer>{
                error.graphQLErrors.map(e => {
                    if (e['state'] && Object.keys(e['state']).length > 0) {
                        return Object.keys(e['state']).map(key => <div>{e['state'][key].message}</div>)
                    }
                    else {
                        error.graphQLErrors.map(i => <div>{i.message}</div>)
                    }
                })
            }</ErrorContainer>}
        </Container>
    </>

}

export default RegisterPage
