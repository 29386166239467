import * as React from 'react'
import styled from '@emotion/styled'

import { Spinner } from './Spinner'

const Container = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255,255,255, .5);
    animation: loadingOverlayFadeIn .3s cubic-bezier(0.5, 0, 0.5, 1);

    @keyframes loadingOverlayFadeIn {
        from {
            background: rgba(255,255,255, 0);
        }
        to {
            background: rgba(255,255,255, .5);
        }
    }
`

const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.1);

    animation: loadingOverlayFadeIn2 4s cubic-bezier(0.5, 0, 0.5, 1);

    @keyframes loadingOverlayFadeIn2 {
        0% {
            background: rgba(0,0,0, 0);
        }
        10% {
            background: rgba(0,0,0, .03);
        }
        100% {
            background: rgba(0,0,0, .1);
        }
    }

`

export const LoadingOverlay: React.FunctionComponent = () => {
    return <Container>
        <Overlay>
            <Spinner />
        </Overlay>
    </Container>
}
