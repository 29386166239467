import styled from '@emotion/styled'
import * as React from 'react'
import { createContext, useState, useEffect, useMemo, useContext } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router'
import { useTranslation } from 'react-i18next'

import { MUTATION, parseData, Q, V } from '../data/auth/confirmSignUp'
import { LoadingOverlay } from '../components/LoadingOverlay'

import { Logo, Container, ErrorContainer } from './Login'

const BRAND = 'pt'

const ConfirmPage: React.FunctionComponent<{}> = () => {
    const { t } = useTranslation(localStorage.getItem('localeBranch'))
    const [confirmSignUp, { loading, data, error }] = useMutation<Q, V>(MUTATION)
    const history = useHistory()
    const { code } = useParams()

    const urlParams = new URLSearchParams(window.location.search)
    const email = urlParams.get('email')

    useEffect(() => {
        confirmSignUp({
            variables: {
                code,
                email
            }
        }).then((data) => {
            localStorage.setItem('email', email)
            history.push('/login')
        })
    }, [])


    return <>
        <Logo><img src={`/img/${BRAND}/logo.png`} /></Logo>
        <Container>
            <h1>{t('confirm.header')}</h1>

            {loading && <LoadingOverlay />}
            {error && <ErrorContainer>{error.graphQLErrors.map(i => <div>{i.message}</div>)}</ErrorContainer>}
        </Container>
    </>

}

export default ConfirmPage
