import * as React from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { TimelineFragment } from '../../fragments/Timeline'
import { LoadingArea } from '../../components/LoadingArea'
import { ErrorMessage } from '../../fragments/Timeline'
import { EmptyMessage } from '../structure/StructureStructure'

import { Use as PartnerCountsData } from '../../data/partners/byId/counts'

const PartnerTimelinePage: React.SFC<{}> = () => {
    const { t } = useTranslation(localStorage.getItem('localeBranch'))
    let { partnerId } = useParams()

    const { loading, error, data } = PartnerCountsData(partnerId)

    if (loading) return <LoadingArea size={400} />
    if (error) return <ErrorMessage><span>⚠</span> {t('error.error')}</ErrorMessage>

    const { sbibs, bibs, ibs, clients } = data

    if (!sbibs && !bibs && !ibs && !clients) return <EmptyMessage>{t('error.noStructure')}</EmptyMessage>

    return <TimelineFragment partnerId={partnerId} />
}

export { PartnerTimelinePage }
