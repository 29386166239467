import * as React from 'react'
import { render } from 'react-dom'
import './index.css'
import './global-types'
import { resolve } from 'path'
import { config } from 'dotenv'

import { App } from './pages/App'

import './i18n'

config()

console.log(`!!!!! Current API_URL is ${process.env.API_URL}`)

render(<App />, document.getElementById('root'))
