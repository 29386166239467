import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Use as PartnersData, PartnerPosition } from '../data/partners/byId/partners'
import { Card } from './Card'
import { CardContainer } from './ClientCardsList'
import { CardsSkeletonFragment } from './CardsSkeleton'
import { LoadingArea } from '../components/LoadingArea'
import { LoadMoreBtn } from '../components/LoadMoreBtn'

export { PartnerPosition }

type T = { partnerId: string, position?: PartnerPosition, limit?: number, nextLimit?: number, count: number }

export const PartnerCardsListFragment: React.FunctionComponent<T> = ({ partnerId, position, limit = 4, nextLimit = 8, count }) => {
    const { t } = useTranslation(localStorage.getItem('localeBranch'))
    const { loading, error, data: items, more, loadingMore } = PartnersData({
        partnerId,
        limit,
        position
    })

    if (loading) return <CardsSkeletonFragment limit={limit} count={count} type='partner' />

    const remains = count - items.length

    return <>
        <CardContainer>
            {items.map(item => <Card
                key={item.id}
                id={item.id}
                title={item.name}
                currency={item.aggregated.currency}
                type="partners"
                data={[
                    { label: 'subPartners', value: item.counts.sbibs + item.counts.bibs + item.counts.ibs },
                    { label: 'clients', value: item.counts.clients },
                    { label: 'balance', value: item.aggregated.balance, type: 'currency' },
                    { label: 'equity', value: item.aggregated.equity, type: 'currency' },
                    { label: 'profit', value: item.aggregated.profit, type: 'currency' },
                ]}
            />)}
        </CardContainer>

        {count > 4 && items.length < count  // Are available more items?
            ? loadingMore
                ? <CardsSkeletonFragment limit={nextLimit < remains ? nextLimit : remains} count={count} type='partner' moreButton={remains > nextLimit} />
                : <LoadMoreBtn action={() => more({ limit: nextLimit })}>{t('fetchMore')}</LoadMoreBtn>
            : <></>
        }
    </>
}
