import * as React from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

import { Use as PartnerCountsData } from '../data/partners/byId/countsDeep'
import { AllClientsCardsListFragment } from '../fragments/AllClientsCardsList'
import { LoadingArea } from '../components/LoadingArea'
import { SectionHeader } from '../components/SectionHeader'
import { EmptyMessage } from './structure/StructureStructure'
import { ErrorMessage } from '../fragments/Timeline'

const Container = styled.div`
  background: #fff;
  border: 1px solid hsla(240,4%,64%,.26);
  box-shadow: 0 1px 0 rgba(0,0,0,.16);
  margin-top: 1rem;
  padding-bottom: 1rem;
`

const AllClientsPage: React.SFC<{}> = () => {
  const { t } = useTranslation(localStorage.getItem('localeBranch'))
  const partnerId = localStorage.getItem('partnerId')
  const { loading, error, data } = PartnerCountsData(partnerId)

  if (loading) return <LoadingArea size={400} />
  if (error) return <ErrorMessage><span>⚠</span> {t('error.error')}</ErrorMessage>

  const {
    inStructure: { clients },
  } = data

  if (!clients) return <Container><EmptyMessage>{t('error.noStructure')}</EmptyMessage></Container>

  return (
    <>
      {clients > 0 && (
        <>
          <Container>
            <SectionHeader>
              <h2>
                <em>
                  {t(`clients.header`)} <span>{clients}</span>
                </em>
              </h2>
              <p>{t(`clients.subheader`)}</p>
            </SectionHeader>
            <AllClientsCardsListFragment
              partnerId={partnerId}
              limit={12}
              count={clients}
            ></AllClientsCardsListFragment>
          </Container>
        </>
      )}
    </>
  )
}

export { AllClientsPage }
