import * as React from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

import { ChartBig, ChartType } from '../components/charts/ChartBig'
import { LoadingArea } from '../components/LoadingArea'
import { SectionHeader } from '../components/SectionHeader'
import { simpleId } from '../utils'

type TData = {
    loading: boolean
    error: any
    data: {
        labels: Date[]
        values: number[]
        currency: string
    }
}

type TT = {
    type: ChartType
    cols: {
        key: string
        color: string
        label: string
        data: TData
        valueFnc?: any
    }[]
}

type T = TT & {
    header: string
    desc: string
}

const ErrorMessage = styled.div`
    padding: 5rem 0;
    color: red;
    height: 250px;

    span {
        font-size: 3rem;
        display: block;
    }
`

const Chart: React.FunctionComponent<TT> = ({ type, cols }) => {
    const { t } = useTranslation(localStorage.getItem('localeBranch'))
    const loading = !!cols.map(col => col.data.loading).filter(i => i === true).length
    const errors = !!cols.map(col => col.data.error).filter(i => typeof i !== 'undefined').length

    if (loading) return <LoadingArea size={250} />
    if (errors) return <ErrorMessage><span>⚠</span> {t('error.error')}</ErrorMessage>

    if (!cols[0].data.data) return <ErrorMessage><span>⚠</span> {t('error.error')}</ErrorMessage>

    const keys = cols.map(col => col.key)
    const data = cols[0].data.data.labels.map((label, i) => {
        // example item = {
        //     label: 'May',
        //     withdrawals: 12,
        //     deposits: 3
        // } 
        const item = {
            label
        }
        keys.forEach((key, j) => {
            const val = cols[j].data.data.values[i]
            item[key] = cols[j].valueFnc ? cols[j].valueFnc(val) : val
        })
        return item
    })

    const labels = {}
    cols.forEach(col => {
        labels[col.key] = col.label
    })

    return <ChartBig
        id={simpleId()}
        type={type}
        cols={cols.map(({ key, color }) => { return { key, color } })}
        data={data}
        currency={cols[0].data.data.currency}
        labels={labels}
    />
}

const ChartContainer = styled.div`
    text {
        font-size: 13px;
        fill: black;
    }
    .chart {
        padding: 0 30px 20px;
        border-bottom: 1px solid #f1f1f1;
    }
`

export const BigChartFragment: React.FunctionComponent<T> = ({ type, cols, header, desc }) => {

    return <ChartContainer>
        <SectionHeader>
            <h2><em>{header}</em></h2>
            <p>{desc}</p>
        </SectionHeader>
        <div className={'chart'}>
            <Chart
                type={type}
                cols={cols}
            />
        </div>
    </ChartContainer>
}
