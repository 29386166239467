import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { BigChartFragment } from '../../../fragments/BigChart'

import { Use as VolumeChartData } from '../../../data/partners/byId/charts/volume'

const PartnerVolumeChart: React.FunctionComponent<{ partnerId: string, tkey: string }> = ({ partnerId, tkey }) => {
    const { t } = useTranslation(localStorage.getItem('localeBranch'))

    return <>
        <BigChartFragment
            header={t(`${tkey}.data.charts.volume.header`)}
            desc={t(`${tkey}.data.charts.volume.desc`)}
            type="bar"
            cols={[{ key: 'value', color: '#07ada3', label: t('structure.data.charts.volume.header'), data: VolumeChartData(partnerId) }]}
        />
    </>
}

export { PartnerVolumeChart }
