import gql from 'graphql-tag'
import { get } from 'ts-object-path'
import { useQuery} from '@apollo/react-hooks'
import {
  PartnerByMe as Query
} from '../_generated/PartnerByMe'

export const QUERY = gql`
query PartnerByMe {
  partners {
    byMe {
      id
      email
      partnerCodes {
        code
      }
    }
  }
}`

export type Q = Query
export type R = Q['partners']['byMe']

export const parseData = (data: Q): R => {
  return get(data, p => p.partners.byMe)
}

export function Use() {
  const query = useQuery<Q>(QUERY)

  const { loading, error, data } = query

  return { loading, error, data: parseData(data) }
}
