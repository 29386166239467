/** @jsx nativeEvents */
import nativeEvents from '../../native-events'
import { Trans, useTranslation } from 'react-i18next'

import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { useRef, useState } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'

import { nextOrSubmit, isEnterKey } from './utils'

type T = {
    onSubmit: any
}

export const RegisterForm: React.FunctionComponent<T> = ({ onSubmit: onSubmitFnc }) => {
    const { t } = useTranslation(localStorage.getItem('localeBranch'))
    const emailInput = useRef(null)
    const passwordInput = useRef(null)
    const passwordInput2 = useRef(null)
    const checkboxAgree = useRef(null)
    const inputsToValidate = [
        emailInput,
        passwordInput,
        passwordInput2,
        checkboxAgree
    ]

    const onSubmit = e => onSubmitFnc({
        email: emailInput.current.value,
        password: passwordInput.current.value,
        password2: passwordInput2.current.value
    })

    return <form onSubmit={onSubmit} >
        <vaadin-email-field
            label={t('register.form.email.label')}
            name="email"
            required
            _onKeydown={e => isEnterKey(e) && nextOrSubmit(e, inputsToValidate, onSubmit)}
            ref={emailInput}
            error-message={t('register.form.email.error')}
            clear-button-visible
            autocorrect="off"
            spellcheck="false">
        </vaadin-email-field>

        <vaadin-password-field
            label={t('register.form.password.label')}
            name="password"
            id="password"
            placeholder={t('register.form.password.placeholder')}
            error-message={t('register.form.password.error')}
            minlength="8"
            required
            autoselect
            _onKeydown={e => isEnterKey(e) && nextOrSubmit(e, inputsToValidate, onSubmit)}
            ref={passwordInput}
            autocomplete="new-password"
            spellcheck="false">
        </vaadin-password-field>

        <vaadin-password-field
            label={t('register.form.password2.label')}
            name="password-repeat"
            id="password-repeat"
            placeholder={t('register.form.password2.placeholder')}
            error-message={t('register.form.password2.error')}
            pattern={passwordInput?.current?.value}
            autoselect
            _onKeydown={e => isEnterKey(e) && nextOrSubmit(e, inputsToValidate, onSubmit)}
            ref={passwordInput2}
            autocomplete="repeat-password"
            spellcheck="false">
        </vaadin-password-field>

        <vaadin-checkbox
            ref={checkboxAgree}
        ><small>
            <Trans t={t} i18nKey="register.form.agree.label" components={[<a target="_blank" href={t('register.form.agree.url')}>x</a>]}></Trans>
        </small></vaadin-checkbox>

        <vaadin-button
            id="submit"
            onClick={e => nextOrSubmit(e, inputsToValidate, onSubmit)}
            part="vaadin-login-submit"
            theme="primary contained"
        >{t('register.submit')}</vaadin-button>


    </form>

}