import * as React from 'react'
import { useParams, Link } from 'react-router-dom'
import styled from '@emotion/styled'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import { simpleId } from '../../../utils'
import { Money } from '../../../components/Money'
import { Table, NoItemsMessage } from '../../../components/TradesTable'
import { LoadingArea } from '../../../components/LoadingArea'
import { LoadMoreBtn } from '../../../components/LoadMoreBtn'

import { Use as ClosedData } from '../../../data/accounts/byId/table/history/closed'

export const AccountDetailsClosedPage: React.FunctionComponent<{}> = () => {
    const { t } = useTranslation(localStorage.getItem('localeBranch'))
    const { accountId, type } = useParams()

    const limit = 20
    const nextLimit = 40

    const { loading, error, data: items, more, loadingMore } = ClosedData({
        accountId,
        limit
    })

    if (loading) return <LoadingArea size={400} />

    if (items.length < 1) return <NoItemsMessage>No closed trades yet.</NoItemsMessage>

    return <>
        <TradesTable>
            <thead>
                <tr>
                    <td className="date">{t('account.table.date')}</td>
                    <td className="volume">{t('account.table.volume')}</td>
                    <td className="sl">{t('account.table.stopLoss')}</td>
                    <td className="tp">{t('account.table.takeProfit')}</td>
                    <td className="type">{t('account.table.type')}</td>
                    <td className="openPrice">{t('account.table.openPrice')}</td>
                    <td className="closedPrice">{t('account.table.closePrice')}</td>
                    <td className="profit">{t('account.table.profit')}</td>
                </tr>
            </thead>
            <tbody>
                {items.map(item => <tr key={simpleId()}>
                    <td className="date">{moment(item.closeDate).format('MMM Do YY')}</td>
                    <td className="volume">{item.volume}×{item.instrument}</td>
                    <td className="sl">{item.sl}</td>
                    <td className="tp">{item.tp}</td>
                    <td className="type">{item.type}</td>
                    <td className="openPrice">{item.openPrice}</td>
                    <td className="closedPrice">{item.closedPrice}</td>
                    <td className="profit"><Money value={item.profit} currency={item['currency']} />  </td>

                </tr>)}
            </tbody>
        </TradesTable>

        <br />

        {items.length % limit === 0  // Are available more items?
            ? loadingMore
                ? <LoadingArea />
                : <LoadMoreBtn action={() => more({ limit: nextLimit })}>{t('fetchMore')}</LoadMoreBtn>
            : <></>
        }
    </>
}

export const TradesTable = styled(Table)`
    @media only screen and (max-width: 800px) {
        .sl,
        .tp,
        .type,
        .openPrice,
        .closedPrice {
          display: none;
        }
    }

    .profit {
        text-align: right;
    }
`
