import gql from 'graphql-tag'
import { get } from 'ts-object-path'
import { useQuery} from '@apollo/react-hooks'
import {
  AccountByIdClientId,
  AccountByIdClientIdVariables
} from '../../_generated/AccountByIdClientId'

export const QUERY = gql`
query AccountByIdClientId($id: ID!) {
  accounts {
    byId(id: $id) {
      id
      number
      clientId
    }
  }
}`

export type Q = AccountByIdClientId
export type V = AccountByIdClientIdVariables
export type R = Q['accounts']['byId']

export const parseData = (data: Q): R => {
  return get(data, p => p.accounts.byId)
}

export function Use(accountId: string) {
  const query = useQuery<Q, V>(
      QUERY,
      {
          variables: {
              id: accountId,
          }
      }
  )

  const { loading, error, data } = query

  return { loading, error, data: parseData(data) }
}
