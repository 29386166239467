import * as React from 'react'

export const positiveColor = '#07ada3' // green
export const negativeColor = '#ff654d' // red


const nf = Intl.NumberFormat()

export function old_abbreviateNumber(value: number): string {
    if (value >= 1000000 || value <= -1000000) {
        var suffixes = ['', 'k', 'M', 'b', 't']
        var suffixNum = Math.floor(('' + value).length / 3)
        let shortValue
        for (var precision = 2; precision >= 1; precision--) {
            shortValue = parseFloat((suffixNum != 0 ? (value / Math.pow(1000, suffixNum)) : value).toPrecision(precision))
            var dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g, '')
            if (dotLessShortValue.length <= 2) { break }
        }
        if (shortValue % 1 != 0) shortValue = shortValue.toFixed(1)
        return nf.format(shortValue) + suffixes[suffixNum]
    }
    else {
        return nf.format(value)
    }
}

export function abbreviateNumber(num: number, fixed?: number): string {
    if (num === null) { return null; } // terminate early
    if (num === 0) { return '0'; } // terminate early
    fixed = (!fixed || fixed < 0) ? 0 : fixed; // number of decimal places to show
    let b = (num).toPrecision(2).split("e"), // get power
        k = b.length === 1 ? 0 : Math.floor(Math.min(Number(b[1].slice(1)), 14) / 3), // floor at decimals, ceiling at trillions
        c = k < 1 ? num.toFixed(0 + fixed) : (num / Math.pow(10, k * 3)).toFixed(1 + fixed), // divide by power
        d = Number(c) < 0 ? c : Math.abs(Number(c)), // enforce -0 is 0
        e = d + ['', 'K', 'M', 'B', 'T'][k]; // append power
    return e;
}

export const gradientOffset = (data) => {
    const max = Math.max(...data.map((i) => i.value))
    const min = Math.min(...data.map((i) => i.value))
    let off
    if (max <= 0) {
        off = 0
    }
    else if (min >= 0) {
        off = 0 //1
    }
    else {
        off = max / (max - min)
    }
    return { off, max, min }
}

export function gradientHtml({ off, min, max, id }) {
    return <>
        {
            min >= 0 &&  // Only green (+)
            <defs>
                <linearGradient id={`splitColor${id}`} x1="0" y1="0" x2="0" y2="1">
                    <stop offset={0} stopColor={positiveColor} stopOpacity={1} />
                </linearGradient>
            </defs>
        }
        {
            max <= 0 &&  // Only red (-)
            <defs>
                <linearGradient id={`splitColor${id}`} x1="0" y1="0" x2="0" y2="1">
                    <stop offset={0} stopColor={negativeColor} stopOpacity={1} />
                </linearGradient>
            </defs>
        }
        {
            min < 0 &&  // Green and Red (+-)
            <defs>
                <linearGradient id={`splitColor${id}`} x1="0" y1="0" x2="0" y2="1">
                    <stop offset={off} stopColor={positiveColor} stopOpacity={1} />
                    <stop offset={off} stopColor={negativeColor} stopOpacity={1} />
                </linearGradient>
            </defs>
        }
    </>
}
