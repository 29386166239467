import gql from 'graphql-tag'
import { ExecutionResult } from 'graphql'
import {
  ConfirmSignUp,
  ConfirmSignUpVariables,
} from '../_generated/ConfirmSignUp'

export const MUTATION = gql`
mutation ConfirmSignUp($email: String!, $code: String!) {
  user {
    confirmSignUp(email: $email, code: $code)
  }
}`

export type Q = ConfirmSignUp
export type V = ConfirmSignUpVariables
type _R = Q['user']['confirmSignUp']
export type R = _R | ExecutionResult<_R>

export const parseData = (data: Q): R => {
  return data.user.confirmSignUp
}
