import gql from 'graphql-tag'
import { get } from 'ts-object-path'
import { useQuery} from '@apollo/react-hooks'
import {
  DepositsChartByPartnerId_partnersCharts,
  DepositsChartByPartnerId_partnersChartsVariables
} from '../../../_generated/DepositsChartByPartnerId_partnersCharts'
import { Precision } from '../../../_generated/globalTypes'

export const QUERY = gql`
query DepositsChartByPartnerId_partnersCharts($id: ID!, $precision: Precision!) {
  charts {
    byPartnerId(id: $id) {
      id: partnerId
      deposits(precision: $precision) {
        begin
        valuesCount
        values
        labels
        currency
      }
    }
  }
}`

export type Q = DepositsChartByPartnerId_partnersCharts
export type V = DepositsChartByPartnerId_partnersChartsVariables
export type R = Q['charts']['byPartnerId']['deposits']

export const parseData = (data: Q): R => {
  return get(data, p => p.charts.byPartnerId.deposits)

}

export function Use(partnerId: string) {
  const query = useQuery<Q, V>(
      QUERY,
      {
          variables: {
              id: partnerId,
              precision: Precision.MONTH
          }
      }
  )

  const { loading, error, data } = query

  return { loading, error, data: parseData(data) }
}
