import styled from '@emotion/styled'
import * as React from 'react'
import { useRef, useState } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router'
import { useTranslation } from 'react-i18next'

import '@vaadin/vaadin-button/vaadin-button.js'
import '@vaadin/vaadin-text-field/vaadin-email-field.js'
import '@vaadin/vaadin-text-field/vaadin-password-field.js'
import '@vaadin/vaadin-checkbox/vaadin-checkbox.js'

import { MUTATION, parseData, Q, V } from '../data/auth/resetPassword'
import { useAuthDataContext } from '../auth-provider'
import { ResetForm } from '../components/forms/Reset'
import { LoadingOverlay } from '../components/LoadingOverlay'

import { Logo, Container, ErrorContainer } from './Login'

const BRAND = 'pt'

export interface Props extends React.HTMLProps<HTMLDivElement> {
}

const ResetPage: React.FunctionComponent<{}> = () => {
    const { t } = useTranslation(localStorage.getItem('localeBranch'))
    const [reset, { loading, data, error }] = useMutation<Q, V>(MUTATION)
    const [submited, setSubmited] = useState(false);
    const history = useHistory()
    const { code } = useParams()

    const urlParams = new URLSearchParams(window.location.search)
    const email = urlParams.get('email')

    const onSubmit = ({ password }) => {
        reset({
            variables: {
                email,
                code,
                password
            }
        }).then((data) => {
            localStorage.setItem('email', email)
            history.push('/login')
        })
        return false
    }

    return <div>
        <Logo><img src={`/img/${BRAND}/logo.png`} /></Logo>
        <Container>
            <h1>{t('reset.header')}</h1>

            {!submited
                ? <>
                    <p>{t('reset.desc')}</p>
                    <ResetForm onSubmit={onSubmit} />
                </>
                : <p>{t('forgot.finish')}</p>
            }

            {loading && <LoadingOverlay />}
            {error && <ErrorContainer>
                {error.graphQLErrors.map(i => <div>{i.message}</div>)}
            </ErrorContainer>}
        </Container>
    </div>
}

export default ResetPage
