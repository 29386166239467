import React, { useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import styled from '@emotion/styled'

const LoadMoreContainer = styled.div`
    text-align: center;

    button {
        box-sizing: border-box;
        min-width: 90px;
        padding: 10px;
        position: relative;
        background-color: #fff;
        border: 1px solid hsla(240,4%,64%,.26);
        font-family: Aventir heavy,arial;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        color: #8746bd;
        box-shadow: 0 1px 0 #9e9ea6;
        outline: none;
        transition: all .25s linear;

        &:not(:disabled) {
            cursor: pointer;
            
            &:hover {
                text-decoration: none;
                top: -1px;
                border-color: rgba(157,56,218,.5);
            }
        }

        &:disabled {
            opacity: .3;
        }

    }
    
`

export const LoadMoreBtn: React.FunctionComponent<{ action: any, disabled?: boolean }> = ({ action, disabled, children }) => {
    return <LoadMoreContainer>
        <button onClick={action} disabled={disabled}>{children}</button>
    </LoadMoreContainer>
}