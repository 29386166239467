import styled from '@emotion/styled'

export const TDay = styled.div`
    position: relative;
    
    @media (max-width: 750px) {
        border-left: 1px dotted silver;
        margin-left: 40px;
        padding-right: 40px;
    }
    @media (min-width: 750px) {
        width: 50%;
        border-right: 1px dotted silver;
    }

    &.right {
        @media (min-width: 750px) {
            left: 50%;
            border-right: none;
            border-left: 1px dotted silver;
        }

        & > div {
            margin-right: auto;
            margin-left: 40px;

            &:after {
                @media (min-width: 750px) {
                    left: -40px;
                    right: auto;
                }
            }
        }
    }

`

export const TDayHeader = styled.h3`
    font-family: Aventir black,arial;
    padding: 30px 30px 20px;
    font-size: 1.2rem;
    line-height: 1.2rem;
    margin-bottom: 9px;
    padding-bottom: 0;
    text-align: center;
    clear: both;

    @media (max-width: 750px) {
        text-align: left;
    }
`
