import * as React from 'react'
import styled from '@emotion/styled'
import { Route, useHistory } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'

import { Router } from '../router'
import { StructurePage } from './structure/Structure'
import { PartnerPage } from './partner/Partner'
import { ClientPage } from './client/Client'
import { AccountPage } from './account/Account'
import { AllClientsPage } from './AllClients'
import { FooterFragment } from '../fragments/Footer'
import { AppHeaderFragment } from '../fragments/AppHeader'

import { Use as UserData } from '../data/users/me'
import { useAuthDataContext } from '../auth-provider'

const ProtectedPage: React.FunctionComponent<{}> = () => {
    const { t } = useTranslation(localStorage.getItem('localeBranch'))
    const { loading, error, data: user } = UserData()
    const history = useHistory()
    const { onLogout: logout, email } = useAuthDataContext()

    if (loading) return <div>
        <AppHeaderFragment />
    </div>

    if (user?.partnerId) {
        localStorage.setItem('partnerId', user.partnerId)
    }

    const onLogout = () => {
        logout()
        localStorage.clear()
        history.push('/login')
        window.location.reload()
    }

    return <>
        {user?.partnerId
            ? <>
                <AppHeaderFragment />
                <PageContainer>
                    <PageContent>
                        <Router>
                            <Route path='/structure' component={StructurePage}></Route>
                            <Route path='/partner/:partnerId' component={PartnerPage}></Route>
                            <Route path='/client/:clientId' component={ClientPage}></Route>
                            <Route path='/account/:accountId' component={AccountPage}></Route>
                            <Route path='/clients' component={AllClientsPage}></Route>
                        </Router>
                    </PageContent>
                    <FooterFragment />
                </PageContainer>
            </>
            : <>
                <AppHeaderFragment />
                <NoPartner>


                    <Trans t={t} i18nKey="noPartner.desc" components={[<a target="_blank" href={t('noPartner.descLink')}>x</a>]}></Trans>

                    <button onClick={onLogout}>{t('noPartner.logout')}</button>
                </NoPartner>
                <FooterFragment />
            </>
        }

    </>
}

export { ProtectedPage }

const PageContainer = styled.div`
    flex: 1;
    overflow-y: scroll;
`

const PageContent = styled.div`
    max-width: 1150px;
    margin: auto;
    position: relative;
`

const NoPartner = styled.div`
    background: white;
    border: 1px solid rgba(158, 158, 166, 0.26);
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.16);
    padding: 2rem;
    margin: auto;
    margin-bottom: 10rem;
    margin-top: 4rem;
    line-height: 1.4rem;
    width: 100%;

    max-width: 1150px;

    a {
        color: #9d38da;
    }

    button {
        box-sizing: border-box;
        min-width: 90px;
        padding: 10px;
        position: relative;
        background-color: #fff;
        border: 1px solid hsla(240,4%,64%,.26);
        font-family: Aventir heavy,arial;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        color: #8746bd;
        box-shadow: 0 1px 0 #9e9ea6;
        outline: none;
        transition: all .25s linear;
        display: inline-block;
        border: none;
        color: #fff;
        background: #6d00af;
        box-shadow: 0 1px 0 #9e9ea6;
        cursor: pointer;
    }
`