import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { BigChartFragment } from '../../fragments/BigChart'
import { AccountAggregatedHeaderFragment } from '../../fragments/AccountAggregatedHeader'

import { Use as BalanceChartData } from '../../data/accounts/byId/charts/balance'
import { Use as DepositsChartData } from '../../data/accounts/byId/charts/deposits'
import { Use as WithdrawalsData } from '../../data/accounts/byId/charts/withdrawals'
import { Use as ProfitChartData } from '../../data/accounts/byId/charts/profit'



export const AccountDataPage: React.FunctionComponent<{}> = () => {
    const { t, i18n } = useTranslation()
    const { accountId } = useParams()

    return <>
        <AccountAggregatedHeaderFragment accountId={accountId} />

        <BigChartFragment
            header={t('account.data.charts.profit.header')}
            desc={t('account.data.charts.profit.desc')}
            type="area"
            cols={[{ key: 'value', color: '#07ada3', label: t('account.data.charts.profit.header'), data: ProfitChartData(accountId) }]}
        />

        <BigChartFragment
            header={t('account.data.charts.balance.header')}
            desc={t('account.data.charts.balance.desc')}
            type="area"
            cols={[{ key: 'value', color: '#07ada3', label: t('account.data.charts.balance.header'), data: BalanceChartData(accountId) }]}
        />

        <BigChartFragment
            header={t('account.data.charts.depositWithdrawals.header')}
            desc={t('account.data.charts.depositWithdrawals.desc')}
            type="bar"
            cols={[
                { key: 'deposits', color: '#07ada3', label: t('structure.data.charts.depositWithdrawals.tooltip.deposits'), data: DepositsChartData(accountId) },
                { key: 'withdrawals', color: '#ff654d', label: t('structure.data.charts.depositWithdrawals.tooltip.withdrawals'), data: WithdrawalsData(accountId), valueFnc: v => -v }
            ]}
        />


    </>
}

