import {ApolloClient} from 'apollo-client'
import {ApolloLink, from} from 'apollo-link'
import {HttpLink} from 'apollo-link-http'
import {ErrorLink} from 'apollo-link-error'
import {InMemoryCache, IntrospectionFragmentMatcher} from 'apollo-cache-inmemory'
import gql from 'graphql-tag'
import fetch from 'node-fetch'
import {BatchHttpLink} from 'apollo-link-batch-http'

export default function createClient (
  GRAPHQL_URL: string,
  introspectionQueryResultData = {"__schema":{"types":[]}},
  getTokenFunc = localStorage.getItem('token')
) {
  const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData
  })
  
  const httpLink = new HttpLink({
    uri: GRAPHQL_URL,
    fetch: fetch as any,
  })
  
  const batchHttpLink = new BatchHttpLink({
    uri: GRAPHQL_URL,
    fetch: fetch as any,
    batchMax: 30,
    batchKey: operation => {
      const [, batchGroup] = operation.operationName.split('_')
      if (batchGroup) {
        return batchGroup
      }
      return 'default'
    }
  })
  
  const errorLink = new ErrorLink(({graphQLErrors, networkError}) => {
    if (graphQLErrors)
      graphQLErrors.map(({message, locations, path}) =>
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`
        )
      )
    if (networkError) console.log(`[Network error]: ${networkError}`)
  })
  
  const authMiddleware = new ApolloLink((operation, forward) => {
    const token = localStorage.getItem('token')
  
    operation.setContext(({headers}) => ({
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
     }
   }))
    return forward(operation)
  })
  
  const client = new ApolloClient({
    link: from([errorLink, authMiddleware, batchHttpLink]),
    cache: new InMemoryCache({fragmentMatcher}),
    queryDeduplication: true
  })
  
  const unauthorizedClient = new ApolloClient({
    link: httpLink,
    cache: new InMemoryCache()
  })

  return {gql, client, unauthorizedClient}
}
