import gql from 'graphql-tag'
import { ExecutionResult } from 'graphql'
import {
  ForgotPassword,
  ForgotPasswordVariables,
} from '../_generated/ForgotPassword'

export const MUTATION = gql`
mutation ForgotPassword($email: String!) {
  user {
    forgotPassword(email: $email)
  }
}`

export type Q = ForgotPassword
export type V = ForgotPasswordVariables
type _R = Q['user']['forgotPassword']
export type R = _R | ExecutionResult<_R>

export const parseData = (data: Q): R => {
  return data.user.forgotPassword
}
