import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { Use as PartnerData } from '../../data/partners/byId/details'
import { PartnerCardsListFragment, PartnerPosition } from '../../fragments/PartnerCardsList'
import { ClientCardsListFragment } from '../../fragments/ClientCardsList'
import { LoadingArea } from '../../components/LoadingArea'
import { SectionHeader } from '../../components/SectionHeader'
import { EmptyMessage } from '../structure/StructureStructure'
import { ErrorMessage } from '../../fragments/Timeline'

export const PartnerStructurePage: React.SFC<{}> = () => {
    const { t } = useTranslation(localStorage.getItem('localeBranch'))
    let { partnerId } = useParams()

    const { loading, error, data } = PartnerData(partnerId)

    if (loading) return <LoadingArea size={400} />
    if (error) return <ErrorMessage><span>⚠</span> {t('error.error')}</ErrorMessage>

    const { counts: { sbibs, bibs, ibs, clients } } = data

    if (!sbibs && !bibs && !ibs && !clients) return <EmptyMessage>{t('error.noStructure')}</EmptyMessage>

    return <>
        {sbibs > 0 && <>
            <SectionHeader>
                <h2><em>{t(`tsbip.header`)} <span>{sbibs}</span></em></h2>
                <p>{t(`tsbip.subheader`)}</p>
            </SectionHeader>
            <PartnerCardsListFragment partnerId={partnerId} position={PartnerPosition.SBIB} count={sbibs}></PartnerCardsListFragment>
        </>}

        {bibs > 0 && <>
            <SectionHeader>
                <h2><em>{t(`tbip.header`)} <span>{bibs}</span></em></h2>
                <p>{t(`tbip.subheader`)}</p>
            </SectionHeader>
            <PartnerCardsListFragment partnerId={partnerId} position={PartnerPosition.BIB} count={bibs}></PartnerCardsListFragment>
        </>}

        {ibs > 0 && <>
            <SectionHeader>
                <h2><em>{t(`tip.header`)} <span>{ibs}</span></em></h2>
                <p>{t(`tip.subheader`)}</p>
            </SectionHeader>
            <PartnerCardsListFragment partnerId={partnerId} position={PartnerPosition.IB} count={ibs}></PartnerCardsListFragment>
        </>}

        {clients > 0 && <>
            <SectionHeader>
                <h2><em>{t(`tclient.header`)} <span>{clients}</span></em></h2>
                <p>{t(`tclient.subheader`)}</p>
            </SectionHeader>
            <ClientCardsListFragment partnerId={partnerId} limit={8} count={clients}></ClientCardsListFragment>
        </>}
    </>
}

