import gql from 'graphql-tag'
import { get } from 'ts-object-path'
import { ExecutionResult } from 'graphql'
import { useQuery} from '@apollo/react-hooks'
import {
  Search,
  SearchVariables,
} from './_generated/Search'

export const QUERY = gql`
query Search($query: String!, $offset: Int!, $limit: Int!) {
  search(query: $query) {
    partners(pagination: {offset: $offset, limit: $limit}) {
      id
      name
    }
    clients(pagination: {offset: $offset, limit: $limit}) {
      id
      name
    }
    accounts(pagination: {offset: $offset, limit: $limit}) {
      id
      accountNumber
    }
  }
  
}`

export type Q = Search
export type V = SearchVariables
export type R = {
  partners: Q['search']['partners']
  clients: Q['search']['clients']
  accounts: Q['search']['accounts']
}

export const parseData = (data: Q): R => {
  return get(data, p => p.search)
}

export function Use(queryString: string, limit = 10, offset = 0) {
  const query = useQuery<Q, V>(
    QUERY,
    {
      variables: {
        query: queryString,
        limit,
        offset
      }
    }
  )

  const { loading, error, data } = query

  return { loading, error, data: parseData(data) }
}
