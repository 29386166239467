import * as React from 'react'
import { Route, NavLink, Redirect, useParams } from 'react-router-dom'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

import { Router } from '../../router'
import { AccountDetailsOpenPage } from './table/Open'
import { AccountDetailsClosedPage } from './table/Closed'
import { AccountDetailsTransactionsPage } from './table/Transactions'
import { AccountDetailsFeesPage } from './table/Fees'
import { TabMenu } from '../../components/TabMenu'

export const AccountDetailsPage: React.FunctionComponent<{}> = () => {
    const { t } = useTranslation(localStorage.getItem('localeBranch'))
    const { accountId } = useParams()

    return <>
        <ClickableTabs>
            <li><NavLink exact to={`/account/${accountId}/details`}>{t('account.tabs.open')}</NavLink></li>
            <li><NavLink exact to={`/account/${accountId}/details/closed`}>{t('account.tabs.closed')}</NavLink></li>
            <li><NavLink exact to={`/account/${accountId}/details/transactions`}>{t('account.tabs.transactions')}</NavLink></li>
            <li><NavLink exact to={`/account/${accountId}/details/fees`}>{t('account.tabs.fees')}</NavLink></li>
        </ClickableTabs>

        <TableContainer>
            <Router>
                <Route exact path='/account/:accountId/details' component={AccountDetailsOpenPage}></Route>
                <Route exact path='/account/:accountId/details/closed' component={AccountDetailsClosedPage}></Route>
                <Route exact path='/account/:accountId/details/transactions' component={AccountDetailsTransactionsPage}></Route>
                <Route exact path='/account/:accountId/details/fees' component={AccountDetailsFeesPage}></Route>
            </Router>
        </TableContainer>
    </>
}

const Table = styled.table`
    width: 100%;
    padding-bottom: 2rem;

    tr:nth-of-type(even) {
        background-color: #efefef;
    }
`

const ClickableTabs = styled(TabMenu)`
    margin-bottom: 1rem;
    padding: 2rem 2rem 0;
    border-bottom: 1px solid hsla(240,4%,64%,.26);

    li {
        border: none;

        a {
            display: block;
            padding: 17px 1.6% 13px;
            text-align: center;

            &.active {
                border: 1px solid #e0e0e0;
                color: #9d38da;
                border-bottom-color: white;
                top: 1px;
                position: relative;
            }
        }
    }
`

const TableContainer = styled.div`
    min-height: 400px;
`
