import gql from 'graphql-tag'
import { get } from 'ts-object-path'
import { useQuery} from '@apollo/react-hooks'
import {
  VolumeChartByPartnerId_partnersCharts,
  VolumeChartByPartnerId_partnersChartsVariables
} from '../../../_generated/VolumeChartByPartnerId_partnersCharts'
import { Precision } from '../../../_generated/globalTypes'


export const QUERY = gql`
query VolumeChartByPartnerId_partnersCharts($id: ID!, $precision: Precision!) {
  charts {
    byPartnerId(id: $id) {
      id: partnerId
      volume(precision: $precision) {
        begin
        valuesCount
        values
        labels
        currency
      }
    }
  }
}`

export type Q = VolumeChartByPartnerId_partnersCharts
export type V = VolumeChartByPartnerId_partnersChartsVariables
export type R = Q['charts']['byPartnerId']['volume']

export const parseData = (data: Q): R => {
  return get(data, p=> p.charts.byPartnerId.volume)
}

export function Use(partnerId: string) {
  const query = useQuery<Q, V>(
      QUERY,
      {
          variables: {
              id: partnerId,
              precision: Precision.MONTH
          }
      }
  )

  const { loading, error, data, fetchMore } = query

    /*
  const mutatedData: { labels: Date[], values: number[], currency: string } = React.useMemo(() => {
      return parseData(data)
  }, [data])
  */

  return { loading, error, data: parseData(data) }
}
