import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { BigChartFragment } from '../../../fragments/BigChart'

import { Use as DepositsChartData } from '../../../data/partners/byId/charts/deposits'
import { Use as WithdrawalsData } from '../../../data/partners/byId/charts/withdrawals'

const PartnerDepositWithdrawalsChart: React.FunctionComponent<{ partnerId: string, tkey: string }> = ({ partnerId, tkey }) => {
    const { t } = useTranslation(localStorage.getItem('localeBranch'))

    return <>
        <BigChartFragment
            header={t(`${tkey}.data.charts.depositWithdrawals.header`)}
            desc={t(`${tkey}.data.charts.depositWithdrawals.desc`)}
            type="bar"
            cols={[
                { key: 'deposits', color: '#07ada3', label: t('structure.data.charts.depositWithdrawals.tooltip.deposits'), data: DepositsChartData(partnerId) },
                { key: 'withdrawals', color: '#ff654d', label: t('structure.data.charts.depositWithdrawals.tooltip.withdrawals'), data: WithdrawalsData(partnerId), valueFnc: v => -v }
            ]}
        />
    </>
}

export { PartnerDepositWithdrawalsChart }
