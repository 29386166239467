import React, { useState } from 'react'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useTranslation } from 'react-i18next'

import SvgIcon from '../components/SvgIcon'
import { SearchFragment } from './Search'
import { useAuthDataContext } from '../auth-provider'
import { useHistory } from 'react-router-dom'

import { Use as PartnerData } from '../data/partners/me'
import { Use as UserData } from '../data/users/me'

import { MUTATION as CHANGE_PARTNER, Q, V } from '../data/auth/changePartner'

export const AppHeaderFragment: React.FunctionComponent<{}> = () => {
    const [searchString, setSearchString] = useState('')
    const { t, i18n } = useTranslation()
    const { loading, data: partner } = PartnerData()
    const { loading: loading2, data: user } = UserData()
    const history = useHistory()
    const { onLogout: logout, email } = useAuthDataContext()

    const [changePartner] = useMutation<Q, V>(CHANGE_PARTNER)

    const onLogout = () => {
        logout()
        localStorage.clear()
        history.push('/login')
        window.location.reload()
    }

    const onChangePartner = () => {
        const email = prompt('Email:', '')
        if (email) {
            changePartner({
                variables: {
                    email
                }
            }).then((data) => {
                history.push('/structure')
                window.location.reload()
            }).catch((err) => {
                alert(err)
            })
        }
        return false
    }

    const onChangeLocaleBranch = () => {
        const branch = prompt('Branch:', '')
        localStorage.setItem('localeBranch', branch || 'original')
        window.location.reload()
        return false
    }

    const BRAND = 'pt'

    return <Header>
        <header>
            <img src={`/img/${BRAND}/logo_small.png`} />
            <menu>
                {partner && <>
                    <Link to="/structure">{t('header.clientStructure')}</Link>
                </>}
            </menu>

            <SearchFragment></SearchFragment>

            <UserMenu>
                <SvgIcon size={18}><UserIcon /></SvgIcon>
                {!loading && !loading2 && <div>
                    <em>{email}</em>
                    {user?.isAdmin && <em><small><u>partner:</u></small> {partner.email}</em>}
                    {user?.isAdmin && <button onClick={onChangePartner}>Change Partner</button>}
                    {user?.isAdmin && <em><small><u>locale branch:</u></small> {localStorage.getItem('localeBranch')}</em>}
                    {user?.isAdmin && <button onClick={onChangeLocaleBranch}>Change Locale Branch</button>}
                    <button onClick={onLogout}>{t('header.logout')}</button>
                </div>}
            </UserMenu>
        </header>
        <SubMenu>
            <menu>
                <Link to="/" className="item home"><SvgIcon size={20}><HomeIcon /></SvgIcon></Link>
                {partner && <>
                    <Link to="/clients" className="item"><SvgIcon size={16}><UserIcon2 /></SvgIcon> {t('header.clients')}</Link>
                </>}
            </menu>
        </SubMenu>
    </Header>
}

const Header = styled.div`
    top: 0;
    width: 100%;
    z-index: 2000;
    background: #522974;
    height: 97px;

    header {
        //max-width: 1150px;
        margin: auto;
        display: flex;

        & > img {
            position: absolute;
            padding: 15px;
            height: 53px;
        }
    
        menu {
            padding: 0 0 0 60px;
            line-height: 50px;
            height: 50px;

            a {
                display: inline-block;
                margin: 0 14px;
                color: hsla(0,0%,100%,.8);
                position: relative;
                text-decoration: none;
                font-size: 16px;
                font-weight: 400;
                transition: color .15s linear;
            }
        }
    }
`

const UserMenu = styled.div`
    position: relative;
    display: inline-block;
    padding: 15px 17px;
    position: absolute;
    top: 0;
    right: 0;
    color: white;

    & > div {
        display: none;
        right: 0;
        position: absolute;
        background-color: #522974b0;
        min-width: 160px;
        box-shadow: 0 8px 16px 0 rgba(0,0,0,.2);
        z-index: 1;
        color: #fff;
        top: 50px;

        em {
            display: block;
            font-size: 80%;
            font-weight: 200;
            white-space: nowrap;
            padding: 0 20px;
            opacity: .8;
            padding: 18px;
        }

        button {
            margin: 7px 20px 0 0;
            font-family: Aventir medium,arial;
            background: transparent;
            border: none;
            box-shadow: none;
            display: block;
            width: 100%;
            text-align: right;
            color: white;
            padding: 18px;

            &:hover {
                background: hsla(0,0%,100%,.1);
            }
        }
    }

    &:hover {
        background: hsla(0,0%,100%,.2);

        & > div {
            display: block;
        }
    }
`

const SubMenu = styled.div`
    background: #fff;
    border-bottom: 1px solid #e5e5e5;

    menu {
        max-width: 1150px;
        margin: auto;
        position: relative;
        line-height: 45px;

        .item {
            svg {
                position: relative;
                top: 2px;
            }

            display: inline-block;
            padding: 0 20px;
            color: #5d5d5d;
            position: relative;
            text-decoration: none;
            font-family: Aventir medium,arial;
            font-size: 14px;
            transition: color .15s linear;
        }

        .home {
            svg {
                position: relative;
                left: -4px;
                top: 3px;
            }

            &:before {
                content: "";
                width: 30px;
                height: 30px;
                background: #e3e3e3;
                border-radius: 3px;
                position: absolute;
                top: 6px;
                left: 11px;
            }

            &:after {
                position: absolute;
                right: 0;
                content: "|";
                color: #ececed;
            }
        }
    }
`

const UserIcon = () =>
    <g stroke="currentColor">
        <g stroke="currentColor">
            <g stroke="currentColor">
                <path fill="none" stroke="currentColor" strokeLinejoin="round" strokeMiterlimit="10" d="M12,23.5h11.5c0,0,0-2.057-1-4.057
                    c-0.746-1.492-4-2.5-8-4v-3c0,0,1.5-1,1.5-3c0.5,0,1-2,0-2.5c0-0.298,1.339-2.802,1-4.5c-0.5-2.5-7.5-2.5-8-0.5c-3,0-1,4.594-1,5
                    c-1,0.5-0.5,2.5,0,2.5c0,2,1.5,3,1.5,3v3c-4,1.5-7.255,2.508-8,4c-1,2-1,4.057-1,4.057H12z"></path>
            </g>
        </g>
    </g>

const UserIcon2 = () =>
    <g fill="currentColor">
        <g fill="currentColor">
            <path d="M16.489,9.754c0.808-0.764,0.749-2.433,0.094-3c0.521-1.385,1.189-2.997,0.907-4.409C17.144,0.614,14.686,0,12.686,0
                c-1.551,0-3.435,0.391-4.029,1.461C8.06,1.527,7.599,1.774,7.284,2.198C6.416,3.37,7.009,5.169,7.436,6.735
                C6.781,7.303,6.703,8.99,7.511,9.754c0.111,1.588,1.05,2.561,1.489,2.934v2.408c-0.421,0.157-0.833,0.309-1.234,0.456
                c-3.471,1.276-5.979,2.199-6.713,3.668C0.012,21.301,0,23.411,0,23.5C0,23.776,0.224,24,0.5,24h23c0.276,0,0.5-0.224,0.5-0.5
                c0-0.089-0.012-2.199-1.053-4.28c-0.735-1.47-3.243-2.392-6.714-3.668c-0.4-0.148-0.813-0.299-1.233-0.456v-2.408
                C15.439,12.314,16.378,11.342,16.489,9.754z"></path>
        </g>
    </g>

const HomeIcon = () =>
    <g stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" fill="none">
        <path d="M3.5 13.5v10h6v-7h5v7h6v-9.5M.5 13l11.5-11.5 11.5 11.5M16 2.5h3.5v3.5"></path>
    </g>
