import gql from 'graphql-tag'
import {
  ChangePartner,
  ChangePartnerVariables,
} from '../_generated/ChangePartner'

export const MUTATION = gql`
mutation ChangePartner($email: String!) {
  user {
    adminChangePartner(email: $email)
  }
}`

export type Q = ChangePartner
export type V = ChangePartnerVariables
