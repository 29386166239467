/** @jsx nativeEvents */
import nativeEvents from '../../native-events'
import { useTranslation } from 'react-i18next'

import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { useRef, useState } from 'react'
import { nextOrSubmit, isEnterKey } from './utils'

type T = {
    onSubmit: any
}

export const ResetForm: React.FunctionComponent<T> = ({ onSubmit: onSubmitFnc }) => {
    const { t } = useTranslation(localStorage.getItem('localeBranch'))
    const passwordInput = useRef(null)
    const passwordInput2 = useRef(null)

    const loginEmail = localStorage.getItem('loginEmail')

    const inputsToValidate = [
        passwordInput,
        passwordInput2
    ]

    const onSubmit = e => onSubmitFnc({
        password: passwordInput?.current.value
    })

    return <form onSubmit={onSubmit} action="?" method="post" >
        <vaadin-password-field
            label={t('reset.form.password.label')}
            name="password"
            id="password"
            placeholder={t('reset.form.password.placeholder')}
            error-message={t('reset.form.password.error')}
            minlength="8"
            required
            autoselect
            _onKeydown={e => isEnterKey(e) && nextOrSubmit(e, inputsToValidate, onSubmit)}
            ref={passwordInput}
            autocomplete="new-password"
            spellcheck="false">
        </vaadin-password-field>

        <vaadin-password-field
            label={t('reset.form.password2.label')}
            name="password-repeat"
            id="password-repeat"
            placeholder={t('reset.form.password2.placeholder')}
            error-message={t('reset.form.password2.error')}
            pattern={passwordInput?.current?.value}
            required
            autoselect
            _onKeydown={e => isEnterKey(e) && nextOrSubmit(e, inputsToValidate, onSubmit)}
            ref={passwordInput2}
            autocomplete="repeat-password"
            spellcheck="false">
        </vaadin-password-field>

        <vaadin-button
            id="submit"
            type="submit"
            onClick={e => nextOrSubmit(e, inputsToValidate, onSubmit)}
            part="vaadin-login-submit"
            theme="primary contained"
        >{t('reset.submit')}</vaadin-button>
    </form>

}