import * as React from 'react'
import styled from '@emotion/styled'

export const SectionHeader = styled.div`
    h2 {
        margin-bottom: 4px;
        font-family: Aventir black,arial;
        padding: 30px 30px 20px;
        font-size: 1.2rem;
        line-height: 1.2rem;
        //color: #8505d4;
        margin-bottom: 9px;
        padding-bottom: 0;

        em {
            border-bottom: 3px solid #b2c609;

            span {
                display: inline-block;
                font-family: Aventir medium,arial,sans-serif;
                background: #f2f2f2;
                color: #5c5c5c;
                padding: 0 5px;
            }
        }
    }
    p {
        font-size: 1rem;
        color: #838383;
        padding-left: 30px;
        padding-bottom: 20px;
        padding-right: 120px;
        line-height: 1.2rem;

        @media (max-width: 800px) {
            padding-right: 30px;
        }
    }
`
