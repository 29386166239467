import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { BigChartFragment } from '../../fragments/BigChart'
import { ClientAggregatedHeaderFragment } from '../../fragments/ClientAggregatedHeader'

import { Use as VolumeChartData } from '../../data/clients/byId/charts/volume'
import { Use as BalanceChartData } from '../../data/clients/byId/charts/balance'
import { Use as DepositsChartData } from '../../data/clients/byId/charts/deposits'
import { Use as WithdrawalsData } from '../../data/clients/byId/charts/withdrawals'
import { Use as ProfitChartData } from '../../data/clients/byId/charts/profit'
import { Use as FeesChartData } from '../../data/clients/byId/charts/fees'

export const ClientDataPage: React.FunctionComponent<{}> = () => {
    const { t } = useTranslation(localStorage.getItem('localeBranch'))
    const { clientId } = useParams()

    return <>
        <ClientAggregatedHeaderFragment clientId={clientId} />

        <BigChartFragment
            header={t('client.data.charts.volume.header')}
            desc={t('client.data.charts.volume.desc')}
            type="bar"
            cols={[{ key: 'value', color: '#07ada3', label: 'Volume', data: VolumeChartData(clientId) }]}
        />

        <BigChartFragment
            header={t('client.data.charts.balance.header')}
            desc={t('client.data.charts.balance.desc')}
            type="area"
            cols={[{ key: 'value', color: '#07ada3', label: 'Balance', data: BalanceChartData(clientId) }]}
        />

        <BigChartFragment
            header={t('client.data.charts.depositWithdrawals.header')}
            desc={t('client.data.charts.depositWithdrawals.desc')}
            type="bar"
            cols={[
                { key: 'deposits', color: '#07ada3', label: 'Deposits', data: DepositsChartData(clientId) },
                { key: 'withdrawals', color: '#ff654d', label: 'Withdrawals', data: WithdrawalsData(clientId), valueFnc: v => -v }
            ]}
        />

        <BigChartFragment
            header={t('client.data.charts.profit.header')}
            desc={t('client.data.charts.profit.desc')}
            type="bar"
            cols={[{ key: 'value', color: '#07ada3', label: 'Profit', data: ProfitChartData(clientId) }]}
        />

        <BigChartFragment
            header={t('client.data.charts.fees.header')}
            desc={t('client.data.charts.fees.desc')}
            type="bar"
            cols={[{ key: 'value', color: '#07ada3', label: 'Fees', data: FeesChartData(clientId), valueFnc: v => -v }]}
        />

    </>
}

