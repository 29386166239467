import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { Use as AccountsData } from '../../data/clients/byId/accounts'
import { Card } from '../../fragments/Card'
import { CardContainer } from '../../fragments/ClientCardsList'
import { LoadingArea } from '../../components/LoadingArea'
import { SectionHeader } from '../../components/SectionHeader'

export const ClientStructurePage: React.FunctionComponent<{}> = () => {
    const { t } = useTranslation(localStorage.getItem('localeBranch'))
    let { clientId } = useParams()
    const { loading, error, data: accounts } = AccountsData(clientId)

    if (loading) return <LoadingArea size={400} />

    return <>
        {accounts.length > 0 && <>
            <SectionHeader>
                <h2><em>{t(`taccount.header`)} <span>{accounts.length}</span></em></h2>
                <p>{t(`taccount.subheader`)}</p>
            </SectionHeader>
            <CardContainer>
                {accounts.map(item => <Card
                    key={item.id}
                    id={item.id}
                    title={item.number + ''}
                    currency={item.currency}
                    type="accounts"
                    strategyConnections={item.strategyConnections}
                    data={[
                        { label: 'balance', value: item.balance, type: 'currency' },
                        { label: 'equity', value: item.equity, type: 'currency' },
                        { label: 'profit', value: item.profit, type: 'currency' },
                    ]}
                />)}
            </CardContainer>
        </>}


    </>
}

