import * as React from 'react'
import { Route, NavLink, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Router } from '../../router'
import { PageContainer, Tabs } from '../structure/Structure'
import { AccountDataPage } from './AccountData'
import { AccountDetailsPage } from './AccountDetails'
import { AccountHeaderFragment } from '../../fragments/AccountHeader'
import { LoadingArea } from '../../components/LoadingArea'
import { Use as AccountClientIdData } from '../../data/accounts/byId/clientId'

export const AccountPage: React.SFC<{}> = () => {
    const { t } = useTranslation(localStorage.getItem('localeBranch'))
    let { accountId } = useParams()
    const { loading, error, data } = AccountClientIdData(accountId)

    if (loading) return <LoadingArea size={400} />

    const { id, clientId } = data

    return <div>
        <AccountHeaderFragment clientId={clientId} accountId={accountId} accountNumber={data.number} />

        <Tabs>
            <li><NavLink exact to={'/account/' + accountId}>{t('account.tabs.overview')}</NavLink></li>
            <li><NavLink to={'/account/' + accountId + '/details'}>{t('account.tabs.activity')}</NavLink></li>
        </Tabs>

        <PageContainer>
            <Router>
                <Route exact path='/account/:accountId' component={AccountDataPage}></Route>
                <Route exact path='/account/:accountId/details' component={AccountDetailsPage}></Route>
                <Route exact path='/account/:accountId/details/:type' component={AccountDetailsPage}></Route>
            </Router>
        </PageContainer>
    </div>
}
