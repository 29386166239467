import * as React from 'react'
import { lazy, Suspense } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { useAuthDataContext, checkAuth } from './auth-provider'
import { AppHeaderFragment } from './fragments/AppHeader'

const Loading = () => {
    return <div><AppHeaderFragment /></div>
}

export const PrivateRoute = ({ component, ...options }) => {
    if (!checkAuth()) {
        console.log('redirect to login')
        localStorage.setItem('lastRoute', options.path)
        return <Redirect to="/login" />
    }
    return <Route {...options} component={component} />
}

export const NoPrivateRoute = ({ component, ...options }) => {
    if (checkAuth()) {
        const lastRoute = localStorage.getItem('lastRoute')
        return lastRoute && lastRoute !== options.path
            ? <Redirect to={lastRoute} />
            : <Redirect to="/" />
    }
    return <Route {...options} component={component} />
}

export const Router = (props) => (
    <Switch>
        <Suspense fallback={<Loading />}>
            {props.children}
        </Suspense>
    </Switch>
)
