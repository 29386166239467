import * as React from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

import { simpleId } from '../utils'
import { LoadMoreBtn } from '../components/LoadMoreBtn'

type T = {
    limit: number
    count: number
    type?: string
    moreButton?: boolean
}

export const CardsSkeletonFragment: React.FunctionComponent<T> = ({ limit, count, type, moreButton = true }) => {
    const { t } = useTranslation(localStorage.getItem('localeBranch'))

    const items = new Array(count).fill('')

    return <>
        <CardSkeleton>
            {items.slice(0, limit).map(_ => <div key={simpleId()}>
                <div className="_name"><Skeleton /></div>
                <div className="_chart"></div>
                <div className="_vals">
                    {type === 'partner' && <>
                        <div><div className="_label _0">{t('accountCard.subPartners')}</div><div className="_value _0"><Skeleton /></div></div>
                        <div><div className="_label _3">{t('accountCard.clients')}</div><div className="_value _0"><Skeleton /></div></div>
                    </>}
                    <div><div className="_label _1">{t('accountCard.balance')}</div><div className="_value _1"><Skeleton /></div></div>
                    <div><div className="_label _2">{t('accountCard.equity')}</div><div className="_value _2"><Skeleton /></div></div>
                    <div><div className="_label _3">{t('accountCard.profit')}</div><div className="_value _3"><Skeleton /></div></div>
                </div>
            </div>)}
        </CardSkeleton>
        {count > limit && moreButton && <LoadMoreBtn disabled action={() => void 0}>{t('fetchMore')}</LoadMoreBtn>}
    </>
}
const CardContainer = styled.div`
    padding: 0 1rem;

    & > div {
        width: calc(25% - 20px);
        display: inline-block;
        margin: 0 10px 19px;

        @media (max-width: 1024px) {
            width: calc(50% - 20px);
        }

        @media (max-width: 600px) {
            width: calc(100% - 20px);
        }
    }
`

export const Skeleton = styled.span`
    display: inline-block;
    background: #f2f2f2;
    position: relative;

    &:before {
        content: '';
        width: 0;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: #dcdada;
        animation: skeleton 3s ease-in-out infinite;
    }
`

export const CardSkeleton = styled(CardContainer)`
    @keyframes showCard {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    & > div {
        flex-direction: column;
        border: 1px solid hsla(240,4%,64%,.4);
        cursor: pointer;
        overflow: hidden;
        position: relative;
        border-radius: 6px;
        border: 1px solid rgba(0,0,0,.1);
        border-bottom: 1px solid rgba(0,0,0,.2);
        border-top: 1px solid rgba(0,0,0,.05);
        box-shadow: 0 1px 4px 0 #e9e9e9;
        -webkit-transition: box-shadow .15s linear;
        transition: box-shadow .15s linear;
        cursor: pointer;
        animation: showCard .3s ease-in-out;
        opacity: 1;
        padding: 20px 0;

        ._name {
            display: flex;
            justify-content: center;
            height: 60px;

            span {
                width: 50%;
                height: 15px;
            }
        }
        ._chart {
            height: 100px;
        }
        ._vals {
            padding: 10px 30px;
            font-family: Aventir heavy,Arial,sans-serif;
            font-size: 13px;
            line-height: 15px;
            color: #4a4a4a;

            & > div {
                display: flex;
                
                ._label {
                    flex: 2;
                    font-size: .8rem;
                    line-height: 1.1rem;
                    color: rgba(0,0,0,.6);

                }
                ._value {
                    text-align: right;
                    span {
                        height: 14px;

                    }
                    &._1 span {
                        width: 6rem;
                    }
                    &._2 span {
                        width: 4rem;
                    }
                    &._3 span {
                        width: 4.5rem;
                    }
                    &._0 span {
                        width: 1.5rem;
                    }
                }
            }
        }
    }
`
