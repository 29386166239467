import styled from '@emotion/styled'

export const TabMenu = styled.ul`
    display: flex;
    margin: 0;
    padding: 0;

    li {
        flex: 1;
        text-align: center;
        list-style: none;
        //border: 1px solid silver;

        a {
            text-decoration: none;
            display: block;
            //padding: 1rem;
            color: black;
            height: 100%;
        }
    }
`
