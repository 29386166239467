import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import SvgIcon from '../components/SvgIcon'
import { Use as partnerData } from '../data/partners/byId/details'
import { BreadCrumbsFragment } from '../components/BreadCrumbs'
import { BackButton } from '../components/BackButton'
import { Use as PartnersData, PartnerPosition } from '../data/partners/byId/partners'
export { PartnerPosition }

export const PartnerHeaderFragment: React.FunctionComponent<{ partnerId: string }> = ({ partnerId }) => {
    const { t } = useTranslation(localStorage.getItem('localeBranch'))
    const { loading, error, data } = partnerData(partnerId)

    if (loading) return <div>
        <BackButton>{t('backToYou')}</BackButton>
        <TopContainer>
            <BreadCrumbsFragment items={[]} />
            <h1>
                <SvgIcon size={26}><ClientIcon /></SvgIcon>
                <NameSkeleton />
            </h1>
        </TopContainer>
    </div>

    const { name, ancestors, id } = data

    const breadCrumbsItems = ancestors[0] ? ancestors[0].map(ancestor => {
        return {
            id: ancestor.id,
            name: ancestor.name,
            link: `/partner/${ancestor.id}`
        }
    }) : []

    breadCrumbsItems.push({
        id,
        name,
        link: `/partner/${id}`
    })

    return <div>
        <BackButton>{t('backToYou')}</BackButton>
        <TopContainer>
            <BreadCrumbsFragment items={breadCrumbsItems} />
            <h1>
                <SvgIcon size={26}><ClientIcon /></SvgIcon>
                <em>{name}</em> {t('clientHeader.overview')}</h1>
        </TopContainer>
    </div>
}

export const TopContainer = styled.div`
    background: #fff;
    border-left: 3px solid #8746bd;
    margin-bottom: 15px;
    box-shadow: 0 2px 4px rgba(0,0,0,.16);

    h1 {
        font-family: Aventir black;
        font-size: 24px;
        line-height: 80px;
        padding: 0 25px 0 74px;
        position: relative;
        color: #313131;
        height: 80px;

        svg {
            position: absolute;
            top: 1.5rem;
            left: 2rem;
        }
    }
`

export const NameSkeleton = styled.span`
    display: inline-block;
    background: #f2f2f2;
    position: relative;
    width: 270px;
    height: 29px;
    margin-right: 1rem;
    top: 5px;
    animation: show-up 1s ease-in;

    &:before {
        content: '';
        width: 0;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: #dcdada;
        animation: skeleton 3s ease-in-out infinite;
    }

    @keyframes show-up {
        0% { opacity: 0; }
        100% { opacity: 1; }
    }
`

export const ClientIcon = () =>
    <g fill="currentColor">
        <g fill="currentColor">
            <path d="M16.489,9.754c0.808-0.764,0.749-2.433,0.094-3c0.521-1.385,1.189-2.997,0.907-4.409C17.144,0.614,14.686,0,12.686,0
                c-1.551,0-3.435,0.391-4.029,1.461C8.06,1.527,7.599,1.774,7.284,2.198C6.416,3.37,7.009,5.169,7.436,6.735
                C6.781,7.303,6.703,8.99,7.511,9.754c0.111,1.588,1.05,2.561,1.489,2.934v2.408c-0.421,0.157-0.833,0.309-1.234,0.456
                c-3.471,1.276-5.979,2.199-6.713,3.668C0.012,21.301,0,23.411,0,23.5C0,23.776,0.224,24,0.5,24h23c0.276,0,0.5-0.224,0.5-0.5
                c0-0.089-0.012-2.199-1.053-4.28c-0.735-1.47-3.243-2.392-6.714-3.668c-0.4-0.148-0.813-0.299-1.233-0.456v-2.408
                C15.439,12.314,16.378,11.342,16.489,9.754z"></path>
        </g>
    </g>
