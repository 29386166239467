import React, { Suspense } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Router, PrivateRoute, NoPrivateRoute } from '../router'
import { ProtectedPage } from './Protected'
import LoginPage from './Login'
import RegisterPage from './Register'
import ForgotPage from './Forgot'
import ResetPage from './Reset'
import ConfirmPage from './Confirm'

const IndexPage: React.SFC<{}> = () => {
  const { t } = useTranslation(localStorage.getItem('localeBranch'))

  return (
    <>
      <Suspense fallback={<div> </div>}>
        <Router>
          <Switch>
            <Route path="/login" component={LoginPage}></Route>
            <Route path="/register" component={RegisterPage}></Route>
            <Route path="/forgot" component={ForgotPage}></Route>
            <Route path="/reset/:code" component={ResetPage}></Route>
            <Route path="/confirm/:code" component={ConfirmPage}></Route>

            <PrivateRoute path="/structure" component={ProtectedPage}></PrivateRoute>
            <PrivateRoute path="/partner" component={ProtectedPage}></PrivateRoute>
            <PrivateRoute path="/client" component={ProtectedPage}></PrivateRoute>
            <PrivateRoute path="/account" component={ProtectedPage}></PrivateRoute>
            <PrivateRoute path="/clients" component={ProtectedPage}></PrivateRoute>

            <Route render={() => <Redirect to="/structure" />} />
          </Switch>
        </Router>
      </Suspense>
    </>
  )
}

export { IndexPage }
