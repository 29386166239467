import * as React from 'react'
import styled from '@emotion/styled'

import { Spinner } from './Spinner'

type T = {
    size: number
}

const Container = styled.div<T>`
    height: ${(props: T) => props.size}px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
`

export const LoadingArea: React.FunctionComponent<{ size?: number }> = ({ size = 250 }) => {
    return <Container size={size}>
        <Spinner />
    </Container>
}
