import * as React from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

import { Use as PartnerData } from '../data/partners/byId/details'
import { Money } from '../components/Money'
import { TabMenu } from '../components/TabMenu'
import { Skeleton } from './CardsSkeleton'

export const PartnerAggregatedHeaderFragment: React.FunctionComponent<{ partnerId: string }> = ({ partnerId }) => {
    const { t } = useTranslation(localStorage.getItem('localeBranch'))
    const { loading, error, data } = PartnerData(partnerId)

    if (loading) return <SkeletonTabs>
        <li><h3>{t('data.overview.balance')}</h3><div><Skeleton /></div></li>
        <li><h3>{t('data.overview.equity')}</h3><div><Skeleton /></div></li>
        <li><h3>{t('data.overview.profit')}</h3><div><Skeleton /></div></li>
        <li><h3>{t('data.overview.subpartners')}</h3><div><Skeleton className="_small" /></div></li>
        <li><h3>{t('data.overview.clients')}</h3><div><Skeleton className="_small" /></div></li>
    </SkeletonTabs>

    if (error) return <div>error!!</div>

    const { aggregated: { balance, equity, profit, currency }, counts: { sbibs, bibs, ibs, clients } } = data

    return <Tabs>
        <li>
            <h3>{t('data.overview.balance')}</h3>
            <div><Money value={balance} currency={currency} /></div>
        </li>
        <li>
            <h3>{t('data.overview.equity')}</h3>
            <div><Money value={equity} currency={currency} /></div>
        </li>
        <li>
            <h3>{t('data.overview.profit')}</h3>
            <div><Money value={profit} currency={currency} /></div>
        </li>
        <li>
            <h3>{t('data.overview.subpartners')}</h3>
            <div>{sbibs + bibs + ibs}</div>
        </li>
        <li>
            <h3>{t('data.overview.clients')}</h3>
            <div>{clients}</div>
        </li>
    </Tabs>
}

export const Tabs = styled(TabMenu)`
    padding: 30px 20px 0;
    border-bottom: 1px solid hsla(240,4%,64%,.26);

    li {
        text-align: left;
        flex: 1;
        display: block;
        padding: 1rem;
        //background: white;
        color: #555459;
        text-align: left;
        border-top: 1px solid hsla(240,4%,64%,.26);
        border-left: 1px solid hsla(240,4%,64%,.26);
        position: relative;
        outline: none;
        transition: all .15s linear;

        &:last-child {
            border-right: 1px solid hsla(240,4%,64%,.26);
        }

        h3 {
            margin: 0 0 8px;
            font-family: Aventir black,arial,sans-serif;
            font-size: 1rem;
            color: #8505d4;
            white-space: nowrap;
        }

        div {
            font-size: 1.4rem;
            white-space: nowrap;
        }

        @media (max-width: 1024px) {
            div {
                font-size: 1.2rem;
            }
        }
        
    }

    @media (max-width: 800px) {
        padding: 10px 0 0;
        flex-direction: column;

        li {
            font-size: 1.2rem;
            display: flex;
            border-right: 1px solid hsla(240,4%,64%,.26);
            padding: 1rem 2rem;

            &:first-child {
                border-top: none;
            }

            & > div, h3 {
                flex: 1;
            }

            & > div {
                text-align: right;
            }
        }
    }
`

export const SkeletonTabs = styled(Tabs)`
    li {

        div {
            span {
                width: 80%;
                display: inline-block;
                height: 20px;

                &._small {
                    width: 40px
                }
            }
        }
    }
`
