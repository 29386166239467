/** @jsx nativeEvents */
import nativeEvents from '../native-events'

import * as React from 'react'
import moment from 'moment'
import styled from '@emotion/styled'
import { useTranslation, Trans } from 'react-i18next'
import { Link } from 'react-router-dom'

import '@vaadin/vaadin-checkbox/vaadin-checkbox.js'

import { simpleId } from '../utils'
import { TimelineNotificationsBarFragment } from './TimelineNotificationsBar'
import { LoadingArea } from '../components/LoadingArea'
import { LoadMoreBtn } from '../components/LoadMoreBtn'
import { TimelineRow } from '../components/timeline/Row'
import { TDay, TDayHeader } from '../components/timeline/Day'
import { Money } from '../components/Money'

import { Use as PartnerTimeline } from '../data/partners/byId/timeline'

type T = {
    partnerId: string
    showNotificationsBar?: boolean
}

export const TimelineFragment: React.FunctionComponent<T> = ({ partnerId, showNotificationsBar = false }) => {
    const { t } = useTranslation(localStorage.getItem('localeBranch'))
    const { loading, error, data: items, more, loadingMore } = PartnerTimeline({ partnerId })

    if (loading) return <LoadingArea size={400} />
    if (error) return <ErrorMessage><span>⚠</span> {t('error.error')}</ErrorMessage>

    const dFormats = [{ l: 'timeline.today', f: 'DD.MM' }, { l: 'timeline.yesterday', f: 'DD.MM' }]
    const today = moment().format('YYYY-MM-DD')


    const limit = 10

    return <div>
        {showNotificationsBar && <TimelineNotificationsBarFragment />}

        {items.map((day, i) => <div key={simpleId()}>

            {typeof dFormats[moment(today).diff(day.date, 'days')] !== 'undefined'
                ? <TDayHeader>{t(dFormats[moment(today).diff(day.date, 'days')].l)}, {moment(day.date).format(dFormats[moment(today).diff(day.date, 'days')].f)}</TDayHeader>
                : <TDayHeader>{moment(day.date).format('ddd, DD.MM')}</TDayHeader>
            }

            <TDay className={(i % 2 !== 0 && 'right')} >

                <TimelineRow
                    icon='client'
                    items={day.newClientsInStructure}
                    singleRowMessageFnc={(client) => <Trans i18nKey="timeline.client.oneItem.your" values={{ client: client.name }} components={[<Link to={`/client/${client.id}`}>{client.name}</Link>]} />}
                    sumMessage={t('timeline.client.manyItems.your')}
                    sumRowMessageFnc={(client, i) => <Link to={`/client/${client.id}`}>{client.name}</Link>}
                    key={simpleId()}
                />

                <TimelineRow
                    icon='partner'
                    items={day.newPartnersInStructure}
                    singleRowMessageFnc={(partner) => <Trans i18nKey="timeline.partner.oneItem.your" values={{ partner: partner.name }} components={[<Link to={`/partner/${partner.id}`}>{partner.name}</Link>]} />}
                    sumMessage={t('timeline.partner.manyItems.your')}
                    sumRowMessageFnc={(partner, i) => <Link to={`/partner/${partner.id}`}>{partner.name}</Link>}
                    key={simpleId()}
                />

                <TimelineRow
                    icon='strategy'
                    items={day.strategyConnectionHistory.filter(c => c.type === 'COMPLETED')}
                    singleRowMessageFnc={(connection) => <Trans i18nKey="timeline.strategy.completed.oneItem" values={{ client: connection.client.name, strategy: connection.strategy.name }} components={[<Link to={`/client/${connection.client.id}`}>{connection.client.name}</Link>, <strong>{connection.strategy.name}</strong>]} />}
                    sumMessage={t('timeline.strategy.completed.manyItems')}
                    sumRowMessageFnc={(connection, i) => <Trans i18nKey="timeline.strategy.completed.item" values={{ client: connection.client.name, strategy: connection.strategy.name }} components={[<Link to={`/client/${connection.client.id}`}>{connection.client.name}</Link>, <strong>{connection.strategy.name}</strong>]} />}
                    key={simpleId()}
                />

                <TimelineRow
                    icon='strategy'
                    items={day.strategyConnectionHistory.filter(c => c.type === 'REQUESTED')}
                    singleRowMessageFnc={(connection) => <Trans i18nKey="timeline.strategy.requested.oneItem" values={{ client: connection.client.name, strategy: connection.strategy.name }} components={[<Link to={`/client/${connection.client.id}`}>{connection.client.name}</Link>, <strong>{connection.strategy.name}</strong>]} />}
                    sumMessage={t('timeline.strategy.requested.manyItems')}
                    sumRowMessageFnc={(connection, i) => <Trans i18nKey="timeline.strategy.requested.item" values={{ client: connection.client.name, strategy: connection.strategy.name }} components={[<Link to={`/client/${connection.client.id}`}>{connection.client.name}</Link>, <strong>{connection.strategy.name}</strong>]} />}
                    key={simpleId()}
                />

                <TimelineRow
                    icon='strategy'
                    items={day.strategyConnectionHistory.filter(c => c.type === 'DISCONNECTED')}
                    singleRowMessageFnc={(connection) => <Trans i18nKey="timeline.strategy.disconnected.oneItem" values={{ client: connection.client.name, strategy: connection.strategy.name }} components={[<Link to={`/client/${connection.client.id}`}>{connection.client.name}</Link>, <strong>{connection.strategy.name}</strong>]} />}
                    sumMessage={t('timeline.strategy.disconnected.manyItems')}
                    sumRowMessageFnc={(connection, i) => <Trans i18nKey="timeline.strategy.disconnected.item" values={{ client: connection.client.name, strategy: connection.strategy.name }} components={[<Link to={`/client/${connection.client.id}`}>{connection.client.name}</Link>, <strong>{connection.strategy.name}</strong>]} />}
                    key={simpleId()}
                />

                <TimelineRow
                    icon='strategy'
                    items={day.strategyConnectionHistory.filter(c => c.type === 'RECONNECTED')}
                    singleRowMessageFnc={(connection) => <Trans i18nKey="timeline.strategy.reconnected.oneItem" values={{ client: connection.client.name, strategy: connection.strategy.name }} components={[<Link to={`/client/${connection.client.id}`}>{connection.client.name}</Link>, <strong>{connection.strategy.name}</strong>]} />}
                    sumMessage={t('timeline.strategy.reconnected.manyItems')}
                    sumRowMessageFnc={(connection, i) => <Trans i18nKey="timeline.strategy.reconnected.item" values={{ client: connection.client.name, strategy: connection.strategy.name }} components={[<Link to={`/client/${connection.client.id}`}>{connection.client.name}</Link>, <strong>{connection.strategy.name}</strong>]} />}
                    key={simpleId()}
                />


                <TimelineRow
                    icon='deposit'
                    items={day.transactions.filter(t => t.type === 'DEPOSIT')}
                    singleRowMessageFnc={(t) => <Trans i18nKey="timeline.deposit.oneItem" values={{ client: t.client.name }} components={[<Link to={`/client/${t.client.id}`}>{t.client.name}</Link>, <Money value={t.value} currency={t.account.currency} />]} />}
                    sumMessage={t('timeline.deposit.manyItems')}
                    sumRowMessageFnc={(t, i) => <Trans i18nKey="timeline.deposit.item" values={{ client: t.client.name }} components={[<Link to={`/client/${t.client.id}`}>{t.client.name}</Link>, <Money value={t.value} currency={t.account.currency} />]} />}
                    key={simpleId()}
                />


                <TimelineRow
                    icon='withdrawal'
                    items={day.transactions.filter(t => t.type === 'WITHDRAWAL')}
                    singleRowMessageFnc={(t) => <Trans i18nKey="timeline.withdrawal.oneItem" values={{ client: t.client.name }} components={[<Link to={`/client/${t.client.id}`}>{t.client.name}</Link>, <Money value={t.value} currency={t.account.currency} />]} />}
                    sumMessage={t('timeline.withdrawal.manyItems')}
                    sumRowMessageFnc={(t, i) => <Trans i18nKey="timeline.withdrawal.item" values={{ client: t.client.name }} components={[<Link to={`/client/${t.client.id}`}>{t.client.name}</Link>, <Money value={t.value} currency={t.account.currency} />]} />}
                    key={simpleId()}
                />
            </TDay>
        </div>)}

        {items.length % limit === 0  // Are available more items?
            ? loadingMore
                ? <LoadingArea size={116} />
                : <LoadBtnContainer><LoadMoreBtn action={() => more()}>Fetch More</LoadMoreBtn></LoadBtnContainer>
            : void 0
        }
    </div>
}

const LoadBtnContainer = styled.div`
    margin: 3rem auto 2rem;
`

export const ErrorMessage = styled.div`
    padding: 10rem 0;
    color: red;
    text-align: center;

    span {
        font-size: 3rem;
        display: block;
    }
`
