import gql from 'graphql-tag'
import { get } from 'ts-object-path'
import { useQuery} from '@apollo/react-hooks'
import {
  AccountByIdDetails,
  AccountByIdDetailsVariables
} from '../../_generated/AccountByIdDetails'

export const QUERY = gql`
query AccountByIdDetails($id: ID!) {
  accounts {
    byId(id: $id) {
      id
      number
      clientId
      currency
      balance
      equity
      profit
      type
      platform
      history {
        sums {
          fees {
            amount
          }
          transactions {
            all {
              amount
            }
          }
        }
      }
      strategyConnections {
        status
        strategy {
          name
        }
      }
    }
  }
}`

export type Q = AccountByIdDetails
export type V = AccountByIdDetailsVariables
export type R = Q['accounts']['byId'] & {
  fees: Q['accounts']['byId']['history']['sums']['fees']['amount']
  transactions: Q['accounts']['byId']['history']['sums']['transactions']['all']['amount']
}

export const parseData = (data: Q): R => {
  const details = get(data, p => p.accounts.byId)
  if (details) {
    const fees = details.history.sums.fees.amount
    const transactions = details.history.sums.transactions.all.amount
    return {...details, fees, transactions}
  }
}

export function Use(accountId: string) {
  const query = useQuery<Q, V>(
      QUERY,
      {
          variables: {
              id: accountId,
          }
      }
  )

  const { loading, error, data } = query

  return { loading, error, data: parseData(data) }
}
