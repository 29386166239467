import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { Use as AccountData } from '../data/accounts/byId/details'
import { Money } from '../components/Money'
import { Tabs, SkeletonTabs } from './PartnerAggregatedHeader'

export const AccountAggregatedHeaderFragment: React.FunctionComponent<{ accountId: string }> = ({ accountId }) => {
    const { t } = useTranslation(localStorage.getItem('localeBranch'))
    const { loading, error, data } = AccountData(accountId)

    if (loading) return <SkeletonTabs>
        <li><h3>{t('account.details.profit')}</h3><div><span></span></div></li>
        <li><h3>{t('account.details.balance')}</h3><div><span></span></div></li>
        <li><h3>{t('account.details.transactions')}</h3><div><span></span></div></li>
        <li><h3>{t('account.details.equity')}</h3><div><span className="_small"></span></div></li>
        <li><h3>{t('account.details.openpl')}</h3><div><span className="_small"></span></div></li>
    </SkeletonTabs>

    if (error) return <div>error!!</div>

    const { balance, equity, profit, transactions, currency } = data

    return <Tabs>
        <li>
            <h3>{t('account.details.profit')}</h3>
            <div><Money value={profit} currency={currency} /></div>
        </li>
        <li>
            <h3>{t('account.details.balance')}</h3>
            <div><Money value={balance} currency={currency} /></div>
        </li>
        <li>
            <h3>{t('account.details.transactions')}</h3>
            <div><Money value={transactions} currency={currency} /></div>
        </li>
        <li>
            <h3>{t('account.details.equity')}</h3>
            <div><Money value={equity} currency={currency} /></div>
        </li>
        <li>
            <h3>{t('account.details.openpl')}</h3>
            <div><Money value={equity - balance} currency={currency} /></div>
        </li>
    </Tabs>
}
