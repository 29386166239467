/** @jsx nativeEvents */
import nativeEvents from '../../native-events'
import { useTranslation } from 'react-i18next'

import { Route, Switch, Redirect, useHistory } from 'react-router-dom'
import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { useRef, useState } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'

import { nextOrSubmit, isEnterKey } from './utils'

type T = {
    onSubmit: any
}

export const LoginForm: React.FunctionComponent<T> = ({ onSubmit: onSubmitFnc }) => {
    const { t } = useTranslation(localStorage.getItem('localeBranch'))
    const history = useHistory()
    const [rememberEmail, setRememberEmail] = useState(false)
    const emailInput = useRef(null)
    const passwordInput = useRef(null)
    const inputsToValidate = [
        emailInput,
        passwordInput
    ]

    const loginEmail = localStorage.getItem('loginEmail')

    useEffect(() => {
        setRememberEmail(localStorage.getItem('rememberEmail') === 'true')
    }, [])

    const onSubmit = e => {
        e.stopPropagation()
        return onSubmitFnc({
            email: emailInput.current ? emailInput.current.value : loginEmail,
            password: passwordInput.current.value
        })
    }

    const onAccountChange = e => {
        setRememberEmail(false)
        localStorage.removeItem('rememberEmail')
    }

    const onRemember = e => {
        localStorage.setItem('rememberEmail', e.currentTarget.checked)
    }

    return <form onSubmit={onSubmit} id="login-form">
        {rememberEmail && loginEmail
            ? <div>{t('login.loginAs')} <strong>{loginEmail}</strong> <vaadin-button theme="small" onClick={onAccountChange}>{t('login.remember.change')}</vaadin-button></div>
            : <vaadin-text-field
                label={t('login.form.email.label')}
                name="username"
                id="username"
                pattern="^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$"
                value={loginEmail}
                required
                _onKeydown={e => isEnterKey(e) && nextOrSubmit(e, inputsToValidate, onSubmit)}
                ref={emailInput}
                error-message="Please enter a valid email address"
                clear-button-visible
                autocorrect="off"
                autocomplete="username"
                spellcheck="false">
            </vaadin-text-field>
        }
        <vaadin-password-field
            label={t('login.form.password.label')}
            name="password"
            id="password"
            placeholder="Enter password"
            required
            autoselect
            _onKeydown={e => isEnterKey(e) && nextOrSubmit(e, inputsToValidate, onSubmit)}
            ref={passwordInput}
            autocomplete="current-password"
            spellcheck="false">
        </vaadin-password-field>

        {!rememberEmail &&
            <vaadin-checkbox
                _onChange={onRemember}
                checked={rememberEmail ? 'checked' : undefined}
            ><small>{t('login.form.remember.label')}</small></vaadin-checkbox>
        }

        <button
            type="button"
            id="submit"
            onClick={e => nextOrSubmit(e, inputsToValidate, onSubmit)}
        >{t('login.submit')}</button>

        <vaadin-button
            onClick={() => history.push('/forgot')}
            theme="tertiary small forgot-password"
        >{t('login.forgot')}</vaadin-button>

    </form>

}