import gql from 'graphql-tag'
import { ExecutionResult } from 'graphql'
import {
  SignUp,
  SignUpVariables,
} from '../_generated/SignUp'
import {
  Language as _lng,
} from '../_generated/globalTypes'

export const MUTATION = gql`
mutation SignUp($email: String!, $password: String!, $language: Language!) {
  user {
    signUp(email: $email, password: $password, language: $language) {
      id
      partnerId
      email
    }
  }
}`

export type Q = SignUp
export type V = SignUpVariables
type _R = Q['user']['signUp']
export type R = _R | ExecutionResult<_R>
export type Language = _lng

export const parseData = (data: Q): R => {
  return data.user.signUp
}
