import * as React from 'react'
import styled from '@emotion/styled'
import { Route, Link } from 'react-router-dom'
import { simpleId } from '../utils'

const Bread = styled.ul`
    padding: 0 14px;
    border-bottom: 1px solid hsla(240,4%,64%,.15);
    //height: 41px;
    display: block;

    li {
        display: inline-block;

        a {
            text-decoration: none;
            padding: 0 20px;
            line-height: 40px;
            font-family: Aventir medium,Arial,sans-serif;
            font-size: 14px;
            color: #555459;
            position: relative;

            span {
                position: relative;
                top: 3px;
            }

            &::before {
                content: "";
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 20px 0 20px 10px;
                position: absolute;
                top: -12px;
                border-color: transparent transparent transparent #d1d1d1;
                right: -5px;
            }

            &::after {
                content: "";
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 20px 0 20px 10px;
                position: absolute;
                top: -12px;
                border-color: transparent transparent transparent #fff;
                right: -4px;
            }
        }


        &:last-child {
            a {
                &::before, &::after {
                    display: none;
                }
            }
        }
    }

    
`

type T = {
    items: {
        name: string
        link: string
    }[]
}

let itemsCache = []

const resetCrumb = n => {
    itemsCache = itemsCache.slice(0, n + 1)
    return false
}

export const BreadCrumbsFragment: React.FunctionComponent<T> = ({ items }) => {

    if (items && items.length) {
        itemsCache = items
    }

    return <Bread>
        {itemsCache.length
            ? <li>
                <Link to={'/structure'}>you</Link>
            </li>
            : void 0
        }
        {itemsCache.map((item, i) =>
            <li key={simpleId()} onClick={() => resetCrumb(i)}>
                <Link to={item.link} >
                    {item.name}
                </Link>
            </li>
        )}
    </Bread>
}
