import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { PartnerAggregatedHeaderFragment } from '../../fragments/PartnerAggregatedHeader'
import { LoadingArea } from '../../components/LoadingArea'
import { EmptyMessage } from './StructureStructure'
import { ErrorMessage } from '../../fragments/Timeline'
import { PartnerVolumeChart } from './charts/Volume'
import { PartnerBalanceChart } from './charts/Balance'
import { PartnerDepositWithdrawalsChart } from './charts/DepositWithdrawals'
import { PartnerProfitChart } from './charts/Profit'
import { PartnerFeesChart } from './charts/Fees'

import { Use as PartnerCountsData } from '../../data/partners/byId/counts'

const StructureDataPage: React.FunctionComponent<{}> = () => {
    const { t } = useTranslation(localStorage.getItem('localeBranch'))
    const partnerId = localStorage.getItem('partnerId')

    const { loading, error, data } = PartnerCountsData(partnerId)

    if (loading) return <LoadingArea size={400} />
    if (error) return <ErrorMessage><span>⚠</span> {t('error.error')}</ErrorMessage>

    const { sbibs, bibs, ibs, clients } = data

    if (!sbibs && !bibs && !ibs && !clients) return <EmptyMessage>{t('error.noStructure')}</EmptyMessage>

    return <>
        <PartnerAggregatedHeaderFragment partnerId={partnerId} />

        <PartnerVolumeChart partnerId={partnerId} tkey="structure" />
        <PartnerBalanceChart partnerId={partnerId} tkey="structure" />
        <PartnerDepositWithdrawalsChart partnerId={partnerId} tkey="structure" />
        <PartnerProfitChart partnerId={partnerId} tkey="structure" />
        <PartnerFeesChart partnerId={partnerId} tkey="structure" />

    </>
}

export { StructureDataPage }
