import * as React from 'react'
import styled from '@emotion/styled'
import { Route, Link } from 'react-router-dom'
import { simpleId } from '../utils'

const Back = styled(Link)`
    color: #555459;
    font-family: Aventir black,arial;
    text-decoration: none;
    position: relative;
    padding-left: 43px;
    font-family: Aventir black,Arial,sans-serif;
    font-size: 1.2rem;
    line-height: 5rem;

    span {
        position: absolute;
        left: 0;
        top: 50%;
        line-height: 0;
        margin-top: -13px;
        transition: all .15s linear;
    }

    &:hover {
        span {
            margin-left: -10px;
        }
    }
`

export const BackButton: React.FunctionComponent<{}> = ({children}) =>
    <Back to="/structure">
        <span>
            <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true" width="25px" height="25px">
            <g id="Filled_Icon" fill="currentColor">
                <path d="M23.002,12H3.416l3.293-3.293c0.391-0.391,0.391-1.023,0-1.414c-0.391-0.391-1.023-0.391-1.414,0l-5,5
                    c-0.387,0.386-0.391,1.024,0,1.415l5,4.999c0.391,0.391,1.023,0.391,1.414,0c0.391-0.391,0.391-1.023,0-1.414L3.416,14h19.586
                    c0.552,0,1-0.447,1-1C24.002,12.448,23.554,12,23.002,12z"></path>
            </g>
            </svg>
        </span>
        {children}
    </Back>
