import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'

import SvgIcon from '../components/SvgIcon'
import { SearchBox } from '../components/search/SearchBox'
import { Spinner } from '../components/Spinner'
import { Use as SearchData } from '../data/search'


const getHighlightedText = (text, highlight) => {
    const parts = text.split(new RegExp(`(${highlight})`, 'gi'))
    return <>
        {parts.map((part, i) =>
            part.toLowerCase() === highlight.toLowerCase()
                ? <span key={i} className="highlighted">{part}</span>
                : <span key={i} >{part}</span>
        )}
    </>
}

const useKeyNavigation = (matches, selectedItemIndex, setSelectedItem, history, setSearchActive, searchInputRef, setSearchString) => {
    useEffect(() => {



        const handleKeyEvent = (e) => {

            if (matches && matches.length > 0) {
                let matchCount = matches.length
                // 38: Up Arrow Key 
                if (e.keyCode === 38 || e.which === 38) {
                    e.preventDefault()
                    if (selectedItemIndex > 0 && selectedItemIndex < matchCount) {
                        setSelectedItem(c => c - 1)
                    }
                    else {
                        setSelectedItem(matchCount - 1)
                    }
                }
                // 40: DownArrow KEY
                if (e.keyCode === 40 || e.which === 40) {
                    e.preventDefault()
                    if (!(selectedItemIndex < matchCount - 1)) {
                        setSelectedItem(0)
                    }
                    else {
                        setSelectedItem(c => c + 1)
                    }
                }
                // ENTER KEY
                if (e.keyCode === 13 || e.which === 13) {
                    e.preventDefault()
                    const selectedItem = matches[selectedItemIndex]
                    searchInputRef.current.blur()
                    history.push('/' + selectedItem.type + '/' + selectedItem.id)
                }
            }

            // CTRL + F
            if (e.keyCode === 114 || (e.ctrlKey && e.keyCode === 70)) {
                e.preventDefault()
                searchInputRef.current.focus()
            }

            // ESC
            if (e.keyCode === 27) {
                searchInputRef.current.blur()
                setSearchString('')
            }
        }

        window.addEventListener("keydown", handleKeyEvent)
        return () => window.removeEventListener("keydown", handleKeyEvent)
    }, [
        matches, selectedItemIndex, setSelectedItem
    ])
}

const isNumber = (value) => {
    return typeof value === 'number' || isFinite(value)
}

const cloneJson = (data) => JSON.parse(JSON.stringify(data))

const getSearchResultItemLabel = (item, searchString) => {
    if (isNumber(searchString)) {
        return getHighlightedText(item.accountNumber, searchString)
    }
    else {
        return getHighlightedText(item.name, searchString)
    }
}

export const SearchFragment: React.FunctionComponent = () => {
    const history = useHistory()
    const [searchString, setSearchString] = useState('')
    const [searchActive, setSearchActive] = useState(false)
    const [selectedItemIndex, setSelectedItemIndex] = useState(0)
    const { t } = useTranslation(localStorage.getItem('localeBranch'))
    const { loading, error, data } = SearchData(searchString)

    const searchInputRef = useRef(null)
    const itemsRef = useRef([])

    const matches = []
    if (data) {
        const d = cloneJson(data)
        const accounts = d.accounts.map(a => { a.type = 'account'; return a })
        const clients = d.clients.map(a => { a.type = 'client'; return a })
        const partners = d.partners.map(a => { a.type = 'partner'; return a })
        if (isNumber(searchString)) {
            matches.push(...accounts)
        }
        else {
            matches.push(...partners)
            matches.push(...clients)
        }
    }

    const onChange = e => {
        const str = e.target.value
            .normalize('NFD').replace(/[\u0300-\u036f]/g, '') // remove accents/diacritics
            .replace(/^[^a-zA-Z0-9]+/, '') // remove unsafe char from the beginning of the string
        setSearchString(str)
    }

    const setSelectedItem = (i) => {
        setSelectedItemIndex(i)
        if (itemsRef.current[i]) {
            itemsRef.current[i].scrollIntoView({ behavior: 'smooth' })
        }
    }


    useKeyNavigation(matches, selectedItemIndex, setSelectedItem, history, setSearchActive, searchInputRef, setSearchString)

    return <>
        <MobileSearchIcon onClick={() => { setSearchActive(true); setTimeout(() => searchInputRef.current.focus(), 50) }}>
            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.5247 9.33223H9.87495L9.63077 9.1285C10.4023 8.19186 10.8895 7.01126 10.8895 5.66779C10.8891 2.73615 8.53419 0.374878 5.61145 0.374878C2.72843 0.374878 0.374023 2.73615 0.374023 5.66775C0.374023 8.59902 2.72869 10.9603 5.6519 10.9603C6.9511 10.9603 8.16903 10.4716 9.1027 9.6982L9.34614 9.90156V10.5528L13.4059 14.6241L14.6238 13.4025L10.5233 9.33082L10.5247 9.33223ZM5.65287 9.33223C3.62291 9.33223 1.99871 7.70361 1.99871 5.66794C1.99871 3.63212 3.62261 2.00368 5.6525 2.00368C7.68238 2.00368 9.30629 3.63219 9.30629 5.66794C9.30629 7.70339 7.68231 9.33223 5.65213 9.33223H5.65287Z" fill="currentColor" />
            </svg>
        </MobileSearchIcon>
        <SearchBarArea className={searchActive ? 'active' : ''}>
            <input
                ref={searchInputRef}
                type="text"
                name="searchInput"
                id="searchInput"
                value={searchString}
                onChange={onChange}
                onFocus={() => setSearchActive(true)}
                onBlur={() => setSearchActive(false)}
                autoComplete="new-password"
                placeholder={t('search.placeholder')}
            />

            <SearchIcon>
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.5247 9.33223H9.87495L9.63077 9.1285C10.4023 8.19186 10.8895 7.01126 10.8895 5.66779C10.8891 2.73615 8.53419 0.374878 5.61145 0.374878C2.72843 0.374878 0.374023 2.73615 0.374023 5.66775C0.374023 8.59902 2.72869 10.9603 5.6519 10.9603C6.9511 10.9603 8.16903 10.4716 9.1027 9.6982L9.34614 9.90156V10.5528L13.4059 14.6241L14.6238 13.4025L10.5233 9.33082L10.5247 9.33223ZM5.65287 9.33223C3.62291 9.33223 1.99871 7.70361 1.99871 5.66794C1.99871 3.63212 3.62261 2.00368 5.6525 2.00368C7.68238 2.00368 9.30629 3.63219 9.30629 5.66794C9.30629 7.70339 7.68231 9.33223 5.65213 9.33223H5.65287Z" fill="#4D4D4D" />
                </svg>
            </SearchIcon>

            <Loader className={loading ? 'active' : ''}><Spinner /></Loader>

            {searchString && searchString !== '' &&
                <Eraser onClick={() => setSearchString('')}>×</Eraser>
            }

            {searchActive && !matches.length && searchString && searchString !== '' &&
                <List>
                    <Nothing>Nothing found</Nothing>
                </List>
            }

            {searchActive && matches.length && searchString && searchString !== '' &&
                <List>{matches.map((item, i) =>
                    <li
                        className={selectedItemIndex === i ? 'active' : ''}
                        key={i}
                        ref={el => itemsRef.current[i] = el}
                        onMouseDown={() => { history.push('/' + item.type + '/' + item.id); }}
                        onMouseEnter={() => setSelectedItemIndex(i)}
                    >
                        <span className="label">
                            {getSearchResultItemLabel(item, searchString)}
                        </span>
                        <span className="badge">
                            {item.type}
                        </span>
                    </li>
                )}</List>
            }
        </SearchBarArea>

    </>
}


const List = styled.ul`
    position: absolute;
    width: 100%;
    background: white;
    box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.08), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0575083), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0476886), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.04), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0323114), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0224916);
    margin: 0;
    padding: 0;
    list-style: none;
    line-height: 25px;
    z-index: 20;
    max-height: 50vh;
    overflow: auto;

    li {
        padding: 6px 1rem;
        display: flex;
        border-top: 1px solid #E3E3E3;
        align-items: center;
        transition: background .15s linear;
        cursor: pointer;

        .highlighted {
            background: #D5F1EF;
            padding: 0 1px;
            display: inline-block;
        }

        .email {
            font-size: .9em;
            color: #a7a8a7;
            display: block;
            line-height: 1rem;
        }

        .label {
            flex: 1;
            font-size: 15px;
            display: inline-block;
        }

        .badge {
            background: red;
            display: inline-block;
            text-transform: uppercase;
            background: rgba(0, 0, 0, 0.06);
            font-size: 12px;
            padding: 0px 8px;
            height: 20px;
            line-height: 20px;
        }

        
        &.active {
            background: #ECE7F0;

            
        }
    }

    @media (max-width: 600px) {
        max-height: 100%;

        li {
            padding: 6px 1.5rem;
        }
    }
    
`

const SearchBarArea = styled.div`
    position: absolute;
    left: 50%;
    width: 450px;
    margin-left: -225px;
    z-index: 50;
    
    
    input {
        width: 100%;
        line-height: 37px;
        margin-top: 7px;
        padding: 0 1rem;
        padding-left: 47px;
        outline: none;
        border: none;
        font-size: 15px;
        background: #e3e3e3;
        border-radius: 2px;
        transition: background .1s linear;
    }

    input:focus {
        background: white;
    }

    @media (max-width: 600px) {
        display: none;
        width: 100%;
        left: 0;
        top: 46px;
        margin: unset;
        height: calc(100vh - 46px);
        background: #522974d9;

        &.active {
            display: block;
        }

        input {
            padding-left: 55px;
            height: 50px;
            line-height: 50px;
        }
    }
`

const Loader = styled.div`
    position: absolute;
    width: 37px;
    height: 37px;
    top: 7px;
    right: 20px;
    opacity: 0;
    transition: opacity .3s linear;
    pointer-events: none;

    &.active {
        opacity: 1;
    }

    > div {
        transform: scale(.35);
        filter: grayscale(1) brightness(.4);
        opacity: .3;
        position: relative;
        width: 37px;
        height: 37px;
    }

    @media (max-width: 600px) {
        right: 30px;
        width: 50px;
        height: 50px;
    }
    
`

const Eraser = styled.div`
    position: absolute;
    width: 37px;
    top: 7px;
    right: 1px;
    height: 37px;
    font-size: 22px;
    text-align: center;
    line-height: 37px;
    color: silver;
    cursor: pointer;

    &:hover {
        color: black;
    }

    @media (max-width: 600px) {
        width: 50px;
        height: 50px;
        line-height: 50px;
        
    }
`

const SearchIcon = styled.div`
    position: absolute;
    width: 44px;
    top: 9px;
    left: 1px;
    height: 37px;
    font-size: 22px;
    color: silver;
    pointer-events: none;
    cursor: text;

    svg {
        width: 18px;
        height: 18px;
        position: relative;
        top: 7px;
        left: 13px;
    }

    @media (max-width: 600px) {
        svg {
            top: 13px;
            left: 18px;
        }
        
    }
    
`

const MobileSearchIcon = styled.div`
    display: none;
    position: absolute;
    width: 50px;
    height: 50px;
    top: 0;
    right: 50px;
    font-size: 22px;
    color: #cabdd4;

    svg {
        width: 18px;
        height: 18px;
        position: relative;
        top: 15px;
        left: 16px;
    }

    @media (max-width: 600px) {
        display: block;
        
    }
    
`

const Nothing = styled.div`
    border-top: 1px solid #E3E3E3;
    font-size: 1rem;
    color: #838383;
    text-align: center;
    padding: 2rem;
    line-height: 1.2rem;
`