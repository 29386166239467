/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AccountStatus {
  ACTIVE = "ACTIVE",
  DISABLED = "DISABLED",
  DORMANT = "DORMANT",
  TEST = "TEST",
}

export enum AccountType {
  COMMISSION = "COMMISSION",
  CRYPTO = "CRYPTO",
  ECN = "ECN",
  ECNFREE = "ECNFREE",
  ETFINVESTING = "ETFINVESTING",
  IBCOMMISSION = "IBCOMMISSION",
  INVESTING = "INVESTING",
  PROFIT = "PROFIT",
  REFERRAL = "REFERRAL",
  SPCOMMISSION = "SPCOMMISSION",
  STARTERECN = "STARTERECN",
  STARTERSTP = "STARTERSTP",
  STP = "STP",
  TEST = "TEST",
  VIP1ECN = "VIP1ECN",
  VIP1STP = "VIP1STP",
  VIP2ECN = "VIP2ECN",
  VIP2STP = "VIP2STP",
}

export enum ConnectionStatus {
  CONNECTED = "CONNECTED",
  DISCONNECTED = "DISCONNECTED",
  PENDING_CONNECTION = "PENDING_CONNECTION",
  PENDING_DEPOSIT = "PENDING_DEPOSIT",
  PENDING_RECONNECTION = "PENDING_RECONNECTION",
}

export enum FeeType {
  FEE = "FEE",
  REFUND = "REFUND",
}

export enum Language {
  cs = "cs",
  en = "en",
}

export enum PartnerPosition {
  BIB = "BIB",
  IB = "IB",
  SBIB = "SBIB",
}

export enum Platform {
  CTRADER = "CTRADER",
  MT4 = "MT4",
  MT5 = "MT5",
}

export enum Precision {
  DAY = "DAY",
  MONTH = "MONTH",
}

export enum StrategyConnectionHistoryType {
  COMPLETED = "COMPLETED",
  DISCONNECTED = "DISCONNECTED",
  RECONNECTED = "RECONNECTED",
  REQUESTED = "REQUESTED",
}

export enum TradeType {
  LONG = "LONG",
  SHORT = "SHORT",
}

export enum TransactionType {
  COMMISSION = "COMMISSION",
  CORRECTION = "CORRECTION",
  DEPOSIT = "DEPOSIT",
  TRANSFER = "TRANSFER",
  WITHDRAWAL = "WITHDRAWAL",
}

export enum TransactionsSumType {
  DEPOSIT = "DEPOSIT",
  WITHDRAWAL = "WITHDRAWAL",
}

//==============================================================
// END Enums and Input Objects
//==============================================================
