import gql from 'graphql-tag'
import { get } from 'ts-object-path'
import { useQuery} from '@apollo/react-hooks'
import {
  ClientByIdDetails,
  ClientByIdDetailsVariables
} from '../../_generated/ClientByIdDetails'

export const QUERY = gql`
query ClientByIdDetails($id: ID!) {
  clients {
    byId (id: $id) {
      id
      name
      ancestors {
        id
        name
        position
      }
      aggregated {
        balance
        equity
        deposited
        withdrawn
        currency
        profit
      }
    }
  }
}`

export type Q = ClientByIdDetails
export type V = ClientByIdDetailsVariables
export type R = Q['clients']['byId']

export const parseData = (data: Q): R => {
  return get(data, p => p.clients.byId)

}

export function Use(clientId: string) {
  const query = useQuery<Q, V>(
      QUERY,
      {
          variables: {
              id: clientId,
          }
      }
  )

  const { loading, error, data } = query

  return { loading, error, data: parseData(data) }
}
