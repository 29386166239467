import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import SvgIcon from '../components/SvgIcon'
import { Use as clientData } from '../data/clients/byId/details'
import { BreadCrumbsFragment } from '../components/BreadCrumbs'
import { BackButton } from '../components/BackButton'
import { TopContainer, NameSkeleton, ClientIcon } from './PartnerHeader'

export const ClientHeaderFragment: React.FunctionComponent<{ clientId: string }> = ({ clientId }) => {
    const { t } = useTranslation(localStorage.getItem('localeBranch'))
    const { loading, error, data } = clientData(clientId)

    if (loading) return <div>
        <BackButton>{t('backToYou')}</BackButton>
        <TopContainer>
            <BreadCrumbsFragment items={[]} />
            <h1>
                <SvgIcon size={26}><ClientIcon /></SvgIcon>
                <NameSkeleton />
            </h1>
        </TopContainer>
    </div>

    const { name, ancestors, id } = data

    const breadCrumbsItems = ancestors[0].map(ancestor => {
        return {
            id: ancestor.id,
            name: ancestor.name,
            link: `/partner/${ancestor.id}`
        }
    })

    breadCrumbsItems.push({
        id,
        name,
        link: `/client/${id}`
    })

    return <div>
        <BackButton>{t('backToYou')}</BackButton>
        <TopContainer>
            <BreadCrumbsFragment items={breadCrumbsItems} />
            <h1>
                <SvgIcon size={26}><ClientIcon /></SvgIcon>
                <em>{name}</em> {t('clientHeader.overview')}
            </h1>
        </TopContainer>
    </div>
}
