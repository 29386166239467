/** @jsx nativeEvents */
import nativeEvents from '../../native-events'
import { useTranslation } from 'react-i18next'

import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { useRef, useState } from 'react'
import { nextOrSubmit, isEnterKey } from './utils'

type T = {
    onSubmit: any
}

export const ForgotForm: React.FunctionComponent<T> = ({ onSubmit: onSubmitFnc }) => {
    const { t } = useTranslation(localStorage.getItem('localeBranch'))
    const emailInput = useRef(null)

    const loginEmail = localStorage.getItem('loginEmail')

    const inputsToValidate = [
        emailInput
    ]

    const onSubmit = e => onSubmitFnc({
        email: emailInput?.current.value
    })

    return <form onSubmit={onSubmit} action="?" method="post" >
        <vaadin-email-field
            label={t('forgot.form.email.label')}
            name="email"
            id="email"
            value={loginEmail}
            required
            ref={emailInput}
            error-message={t('forgot.form.email.error')}
            clear-button-visible
            autocorrect="off"
            autocomplete="email"
            spellcheck="false">
        </vaadin-email-field>

        <vaadin-button
            id="submit"
            type="submit"
            onClick={e => nextOrSubmit(e, inputsToValidate, onSubmit)}
            part="vaadin-login-submit"
            theme="primary contained"
        >{t('forgot.submit')}</vaadin-button>
    </form>
}
