import React, { useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

import { simpleId } from '../utils'
import { Use as AllClientsData } from '../data/partners/byId/allClients'
import { Card } from './Card'
import { CardsSkeletonFragment } from './CardsSkeleton'
import { LoadingArea } from '../components/LoadingArea'
import { LoadMoreBtn } from '../components/LoadMoreBtn'

export const CardContainer = styled.div`
  padding: 0 1rem;

  & > div {
    width: calc(25% - 20px);
    display: inline-block;
    margin: 0 10px 19px;

    @media (max-width: 1024px) {
      width: calc(50% - 20px);
    }

    @media (max-width: 600px) {
      width: calc(100% - 20px);
    }
  }
`

type T = {
  partnerId: string
  limit?: number
  nextLimit?: number
  count: number
}

export const AllClientsCardsListFragment: React.FunctionComponent<T> = ({
  partnerId,
  limit = 12,
  nextLimit = 12,
  count,
}) => {
  const { loading, error, data: items, more, loadingMore } = AllClientsData({
    partnerId,
    limit,
  })
  const { t } = useTranslation(localStorage.getItem('localeBranch'))

  if (loading) return <CardsSkeletonFragment limit={limit} count={count} />

  const remains = count - items.length

  return (
    <>
      <CardContainer>
        {items.map(item => (
          <Card
            key={item.id}
            id={item.id}
            title={item.name}
            currency={item.aggregated.currency}
            type="clients"
            data={[
              {
                label: 'balance',
                value: item.aggregated.balance,
                type: 'currency',
              },
              {
                label: 'equity',
                value: item.aggregated.equity,
                type: 'currency',
              },
              {
                label: 'profit',
                value: item.aggregated.profit,
                type: 'currency',
              },
            ]}
          />
        ))}
      </CardContainer>

      {count > 12 && items.length < count  // Are available more items?
        ? loadingMore
          ? <CardsSkeletonFragment limit={nextLimit < remains ? nextLimit : remains} count={count} moreButton={remains > nextLimit} />
          : <LoadMoreBtn action={() => more({ limit: nextLimit })}>{t('fetchMore')}</LoadMoreBtn>
        : <></>
      }
    </>
  )
}
